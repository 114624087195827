import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_ERROR,
    USER_LOGOUT,
} from "../types/authTypes"

const initialState = {
    token: localStorage.getItem('dmFinancialToken'),
    isAuthenticated: false,
    loading: false,
    user: null
};

export const auth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case USER_LOGIN_REQUEST:
            return { ...state, loading: true }
        case USER_LOGIN_SUCCESS:

            return {
                ...state,
                ...payload,
                token: payload,
                isAuthenticated: true,
                loading: false
            }

        case USER_LOGIN_ERROR:
            return {
                ...state,
                ...payload,
                isAuthenticated: false,
                loading: false
            }
        case USER_LOGOUT:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null
            }
        default:
            return state
    }
}

