import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { visuallyHidden } from '@mui/utils';

import ConfirmationMessage from '../../../common/ConfirmationMessage';

import { handleModals } from '../../../redux/actions/handleModalAction';

import {
  updateRequestPdfEmailAction,
  deleteRequestPdfEmailAction,
} from '../../../redux/actions/contentAction';

import { completedSvg, pendingSvg } from '../svg';
import { Typography } from '@mui/material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'fromMail',
    numeric: false,
    disablePadding: true,
    label: 'Email address',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Inquiries Date',
  },
  {
    id: 'isCompleted',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },

  {
    id: 'Action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
    align: 'right',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.align}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const RequestPdf = () => {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */

  const dispatch = useDispatch();

  const getEmailReducer = useSelector((state) => state.getEmailReducer);

  const { post } = getEmailReducer;

  const modelReducer = useSelector((state) => state.modalReducer);

  const deleteGetPdfEmailReducer = useSelector((state) => state.deleteGetPdfEmailReducer);

  const { loading } = deleteGetPdfEmailReducer;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = post.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - post.length) : 0;

  /**
 |--------------------------------------------------
 | check authentication
 |--------------------------------------------------
 */
  const retrievedUserObject =
    localStorage.getItem('dmFinancialToken') &&
    JSON.parse(localStorage.getItem('dmFinancialToken'));

  /**
 |--------------------------------------------------
 | handle status
 |--------------------------------------------------
 */
  const handleStatus = (row) => {
    dispatch(
      updateRequestPdfEmailAction(
        {
          isCompleted: row.isCompleted ? false : true,
          username: retrievedUserObject.username,
        },
        row._id
      )
    );
  };

  /**
  |--------------------------------------------------
  | confirmation modal
  |--------------------------------------------------
  */
  const [itemId, setItemId] = React.useState(null);

  const handleConfirmationModalOpen = (row) => {
    setItemId(row._id);
    dispatch(handleModals(true, 'CONFIRMATION_MODAL'));
  };
  const handleConfirmationModalClose = () => {
    dispatch(handleModals(false, 'CONFIRMATION_MODAL'));
  };

  const handleConfirmationAction = () => {
    dispatch(deleteRequestPdfEmailAction({ username: retrievedUserObject.username }, itemId));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ConfirmationMessage
        openDialog={modelReducer.CONFIRMATION_MODAL.modalStatus}
        handleCloseDialog={handleConfirmationModalClose}
        handleConfirmationAction={handleConfirmationAction}
        message={'Are you sure you want to delete this?'}
        loading={loading}
      />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box padding="1rem">
          <Typography variant="h5">Request for Pdf</Typography>
        </Box>

        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={post.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(post, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding={'normal'}>
                        {row.fromMail}
                      </TableCell>
                      <TableCell>{row.createdAt}</TableCell>
                      <TableCell onClick={() => handleStatus(row)}>
                        {row.isCompleted ? completedSvg : pendingSvg}
                      </TableCell>

                      <TableCell align="right">
                        <DeleteOutlineIcon
                          onClick={() => handleConfirmationModalOpen(row)}
                          style={{ color: 'red', cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={post.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default RequestPdf;
