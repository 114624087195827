import React from "react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Container from "../../../common/Container";
import Hero from "./components/Hero";
import Content from "./components/Content";

import backgroundImg9 from "../../../assets/img/backgroundImg9.PNG";

const IndependentFinancialAdvice = () => {
  const theme = useTheme();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        minHeight={{ xs: "auto", md: "calc(40vh - 64px)" }}
        position={"relative"}
        sx={{
          backgroundImage: `url(${backgroundImg9})`,
          backgroundSize: "cover",
          backgroundPosition: "center right",
          zIndex: 10,
          "&:after": {
            position: "absolute",
            content: '" "',
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: theme.palette.common.black,
            opacity: 0.3,
          },
        }}
      >
        <Box
          position={{ xs: "relative", md: "absolute" }}
          top={{ xs: 0, md: "50%" }}
          width={"100%"}
          height={"50%"}
          sx={{
            transform: { md: "translateY(-50%)" },
          }}
          zIndex={2}
        >
          <Container>
            <Hero />
          </Container>
        </Box>
      </Box>
      <Box bgcolor={theme.palette.alternate.main} overflow={"hidden"}>
        <Container>
          <Content />
        </Container>
      </Box>
    </Box>
  );
};

export default IndependentFinancialAdvice;
