import React from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CardMedia from '@mui/material/CardMedia';

const Main = () => {
  const theme = useTheme();

  /**
|--------------------------------------------------
| redux integration
|--------------------------------------------------
*/
  const ourProcessPostReducer = useSelector((state) => state.ourProcessPostReducer);

  const { post } = ourProcessPostReducer;

  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          gutterBottom
          align={'center'}
          data-aos={'fade-up'}
        >
          Our Process
        </Box>
      </Box>
      <Grid container spacing={4}>
        {post.map((item, i) => (
          <Grid item xs={12} sm={6} md={6} key={i} data-aos={'fade-up'}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 140,
                  }}
                />
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {item.title}
                  </Box>
                  {item.description.map((data, desIndex) => (
                    <Typography
                      align={'left'}
                      variant={data.variant}
                      color="textSecondary"
                      key={desIndex}
                    >
                      {data.description}
                    </Typography>
                    // data.isBr && <br />
                  ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
