import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import financialPlanner from '../../../../assets/img/finacialPlanner.jpg';

const Home = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */

  const certifiedFinancialPlannerPostReducer = useSelector(
    (state) => state.certifiedFinancialPlannerPostReducer
  );

  const { post } = certifiedFinancialPlannerPostReducer;

  return (
    <Grid container>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box>
            <Typography variant={'body1'}>{post.length !== 0 && post[0].paragraph1}</Typography>
          </Box>
          <br />
          <Box>
            <Typography variant={'subtitle2'} component={'p'} color={'textSecondary'}>
              {post.length !== 0 && post[0].paragraph2}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          src={financialPlanner}
          alt="financialPlanner"
          style={{ height: '100%', width: '100%' }}
        />
      </Grid>
    </Grid>
  );
};

export default Home;
