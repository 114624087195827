import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

import upload from '../../../assets/img/upload.PNG';

import { baseURL } from '../../../utils/API_URLS';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UploadModal = (props) => {
  const { openDialog, handleCloseDialog, description } = props;

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isFilePicked, setIsFilePicked] = React.useState(false);

  const changeHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      const previous_file = new File([event.target.files[0]], description, {
        type: 'application/pdf',
      });
      setSelectedFile(previous_file);
    }
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    fetch(`${baseURL}/file/upload`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        closeModal();
        setIsFilePicked(false);
      })

      .catch((error) => {
        setIsFilePicked(false);
      });
  };

  const deleteFile = () => {
    setIsFilePicked(true);
    fetch(`${baseURL}/file/${description}`, { method: 'DELETE' })
      .then((response) => {
        if (response.status === 200) {
          uploadFile();
          setIsFilePicked(true);
        }
      })
      .catch((error) => {
        setIsFilePicked(false);
      });
  };

  const handleSubmission = () => {
    deleteFile();
    //  uploadFile();
  };

  const closeModal = () => {
    handleCloseDialog();
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const handlePdfName = (name) => {
    switch (name) {
      case 'SMSF.pdf':
        return 'SMSF pdf';
      case 'RetirementPlanning.pdf':
        return 'Retirement Planning pdf';
      case 'LifestyleProtection.pdf':
        return 'Lifestyle Protection pdf';
      case 'InvestmentPlanning.pdf':
        return 'Investment Planning pdf';
      case 'EstatePlanning.pdf':
        return 'Estate Planning pdf';
      case 'DebtAdvice.pdf':
        return 'Debt Advice pdf';
      case 'CashFlow.pdf':
        return 'Cash Flow pdf';
      case 'CorporateBrochure.pdf':
        return 'Corporate Brochure pdf';
      case 'RoyalCommission.pdf':
        return 'Royal Commission pdf';

      default:
        return;
    }
  };
  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle
        style={{
          background: 'linear-gradient(to right, #000046, #1cb5e0)',
          color: '#fff',
        }}
      >
        Upload {handlePdfName(description)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
          <Grid container item xs={6}>
            <input
              onChange={changeHandler}
              name="file"
              accept="application/pdf"
              hidden={true}
              type="file"
              id="img-upload"
            />
            <label htmlFor="img-upload">
              <div>
                <img src={upload} alt="upload" style={{ width: '100%', cursor: 'pointer' }} />
              </div>
            </label>
          </Grid>
          <Grid container item xs={6}>
            {!selectedFile ? (
              <Typography style={{ color: 'red' }}>File is not selected.</Typography>
            ) : (
              <Typography style={{ color: 'blue' }}>File is selected.</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size={'large'}
          variant={'contained'}
          style={{ backgroundColor: '#000046', color: '#fff' }}
          onClick={handleSubmission}
          disabled={!selectedFile}
        >
          {isFilePicked ? <CircularProgress style={{ color: '#fff' }} size={25} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadModal;
