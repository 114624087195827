import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import avatarImg from '../../../assets/img/avaratImg.png';

const Reviews = (props) => {
  const { handleModalOpen } = props;
  const theme = useTheme();

  const clientFeedbackPostReducer = useSelector((state) => state.clientFeedbackPostReducer);

  const { post } = clientFeedbackPostReducer;

  return (
    <Grid container spacing={5}>
      {post.map((item, i) => (
        <Grid container item xs={12} sm={12} md={6} lg={6} key={i}>
          <Box
            component={Card}
            borderRadius={5}
            padding={{ xs: 1, sm: 1, md: 1 }}
            overflow={'visible'}
            marginTop={2}
            boxShadow={
              'rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px'
            }
          >
            <CardContent>
              <Box padding={{ xs: 1, sm: 2, lg: 3 }}>
                <Box marginTop={-10} display={'flex'} justifyContent={'center'}>
                  <Avatar
                    alt={item.name}
                    src={avatarImg}
                    sx={{
                      width: 60,
                      height: 60,
                      zIndex: 10,
                      boxShadow: '#11B735 0px 1px 10px',
                    }}
                  />
                </Box>
                <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                  <Box
                    textAlign={'center'}
                    component={ListItemText}
                    primary={item.name}
                    secondary={item.title}
                    margin={0}
                  />
                </Box>
                <Box marginBottom={1}>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <Box key={item} color={theme.palette.secondary.main} display={'inline'}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        width={24}
                        height={24}
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </Box>
                  ))}
                </Box>
                <Box
                  component={Typography}
                  variant={'body1'}
                  fontWeight={400}
                  marginBottom={2}
                  style={{
                    maxHeight: '3rem',
                    overflow: 'hidden',
                  }}
                >
                  {item.feedback}
                </Box>

                <Box
                  component={Typography}
                  variant={'body2'}
                  fontWeight={400}
                  marginBottom={2}
                  width={100}
                  cursor={'pointer'}
                >
                  <Typography color={'primary'} onMouseEnter={() => handleModalOpen(item)}>
                    Read more...
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Reviews;
