import React from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import dmFinancialImage from "../../../../../assets/img/DM Financial service profile.jpeg";

const Partners = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  /**
|--------------------------------------------------
| redux integration
|--------------------------------------------------
*/
  const dmTeamPostReducer = useSelector((state) => state.dmTeamPostReducer);

  const { post } = dmTeamPostReducer;

  return (
    <Box display="flex" flexWrap="wrap" justifyContent={"flex-start"}>
      <Box
        style={{
          position: "absolute",
          zIndex: 0,
          top: 500,
          left: -100,
        }}
      >
        <svg
          width="241"
          height="572"
          viewBox="0 0 241 572"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="-45"
            cy="286"
            r="286"
            fill="url(#paint0_linear_400_170)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_400_170"
              x1="151.352"
              y1="86.5872"
              x2="-212.052"
              y2="458.737"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#11B735" />
              <stop offset="1" stop-color="#8AF7A2" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
      <Box
        style={{
          position: "absolute",
          zIndex: 0,
          top: 1300,
          right: 0,
        }}
      >
        <svg
          width="204"
          height="236"
          viewBox="0 0 104 236"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="118"
            cy="118"
            r="118"
            fill="url(#paint0_linear_400_171)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_400_171"
              x1="199.012"
              y1="35.7248"
              x2="49.0764"
              y2="189.269"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#11B735" />
              <stop offset="1" stop-color="#8AF7A2" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
      <Grid item xs={12} sm={12} md={12} data-aos={"fade-up"}>
        <Box
          component={Card}
          boxShadow={0}
          bgcolor={"transparent"}
          style={{
            float: "left",
            minWidth: matches ? "100%" : 300,
            marginRight: 30,
          }}
        >
          <Box
            component={CardMedia}
            borderRadius={2}
            width={"100%"}
            height={"100%"}
            minHeight={matches ? 520 : 420}
            image={dmFinancialImage}
          />
          <Box component={CardContent} bgcolor={"transparent"} marginTop={-5}>
            <Box component={Card} borderRadius={2}>
              <CardContent>
                <ListItemText
                  primary={post.length !== 0 && post[0].name}
                  secondary={post.length !== 0 && post[0].profession}
                />
              </CardContent>
            </Box>
          </Box>
        </Box>
        {post.length !== 0 &&
          post[0].description.map((data, index) => {
            return (
              <>
                <Typography
                  key={index}
                  variant={data.variant}
                  color="textPrimary"
                  align={"left"}
                  sx={{
                    fontWeight: data.fontWeight,
                  }}
                >
                  {data.description}
                </Typography>
                {data.isBr && <br />}
              </>
            );
          })}
      </Grid>
    </Box>
  );
};

export default Partners;
