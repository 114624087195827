import React from "react";
import { Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";

import TextError from "./TextError";
function FormikDatePicker(props) {
  const {
    label,
    type,
    name,
    error,
    shrink,
    helperText,
    disabled,
    rows,
    multiline,
    ...rest
  } = props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <>
          <TextField
            style={{ backgroundColor: "#fff" }}
            error={error}
            margin="dense"
            variant="outlined"
            id={name}
            label={label}
            type="date"
            inputProps={{
              type: "date",
            }}
            fullWidth
            disabled={disabled}
            InputLabelProps={{
              shrink: shrink,
            }}
            {...rest}
            {...field}
          />
          <ErrorMessage component={TextError} name={name} />
        </>
      )}
    </Field>
  );
}

export default FormikDatePicker;
