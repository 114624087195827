import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { Topbar, Footer } from "./components";
import Zoom from "@mui/material/Zoom";
import Sidebar from "./components/Sidebar/Sidebar";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BungalowIcon from "@mui/icons-material/Bungalow";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import Container from "../../common/Container";
import { pages } from "../navigation";

const iconsColor = {
  color: "#5DADE2",
};

const actions = [
  {
    icon: <MonetizationOnIcon style={iconsColor} />,
    name: "Cash-flow Management",
    path: "/cash-Flow-Management",
  },
  {
    icon: <AccountBalanceIcon style={iconsColor} />,
    name: "Debt Advice – Mortgage Broker",
    path: "/Debt-Advice",
  },
  {
    icon: <BungalowIcon style={iconsColor} />,
    name: "Estate Planning",
    path: "/Estate-Planning",
  },
  {
    icon: <FeaturedVideoIcon style={iconsColor} />,
    name: "Independent Financial Advice",
    path: "/Investment-Advice",
  },
  {
    icon: <FamilyRestroomIcon style={iconsColor} />,
    name: "Lifestyle and Family Protection",
    path: "/Lifestyle-and-Family-Protection",
  },
  {
    icon: <WbIncandescentIcon style={iconsColor} />,
    name: "Retirement Planning",
    path: "/Retirement-Planning",
  },
  {
    icon: <WorkspacesIcon style={iconsColor} />,
    name: "SMSF Advice & Setup",
    path: "/SMSF-Advice-and-Setup",
  },
  {
    icon: <AccountTreeIcon style={iconsColor} />,
    name: "Our Process",
    path: "/our-process",
  },
];

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const Main = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType,
  props,
}) => {
  const theme = useTheme();
  const [openSidebar, setOpenSidebar] = useState(false);

  let history = useHistory();

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = (props) => {
    setOpenSidebar(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSpeedDial = (path) => {
    history.push(path);
    setOpen(false);
  };

  return (
    <div>
      <HideOnScroll>
        <AppBar
          position={"fixed"}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
          elevation={1}
        >
          <Container paddingY={{ xs: 1 / 2, sm: 1 }}>
            <Topbar
              onSidebarOpen={handleSidebarOpen}
              themeMode={themeMode}
              themeToggler={themeToggler}
              setThemePalette={setThemePalette}
              paletteType={paletteType}
            />
          </Container>
        </AppBar>
      </HideOnScroll>
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant="temporary"
        pages={pages}
      />
      <main>
        <Box height={{ xs: 56, sm: 64 }} />
        {children}
        <ScrollTop {...props}>
          <Fab
            style={{ backgroundColor: "#8E44AD" }}
            size="small"
            aria-label="scroll back to top"
            onClick={() => window.scrollTo(0, 0)}
          >
            <KeyboardArrowUpIcon style={{ color: "#fff" }} />
          </Fab>
        </ScrollTop>
        <Divider />

        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: "fixed", bottom: 70, right: 10 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClickSpeedDial(action.path)}
            />
          ))}
        </SpeedDial>
      </main>
      <Box bgcolor={theme.palette.primary.dark}>
        <Container paddingY={4}>
          <Footer />
        </Container>
      </Box>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Main;
