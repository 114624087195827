export const pages = [
    {
        title: 'Home',
        id: 'Home',
        pages: [
            {
                title: 'Home',
                href: '/',
            },
        ],
    },
    {
        title: 'Services',
        id: 'Services',
        pages: [
            {
                title: 'Cash Flow Management',
                href: '/cash-Flow-Management',
            },
            {
                title: 'Debt Advice',
                href: '/Debt-Advice',
            },
            {
                title: 'Estate Planning',
                href: '/Estate-Planning',
            },
            {
                title: 'Investment Advice',
                href: '/Investment-Advice',
            },
            {
                title: 'Lifestyle and Family Protection',
                href: '/Lifestyle-and-Family-Protection',
            },

            {
                title: 'Retirement Planning',
                href: '/Retirement-Planning',
            },
            {
                title: 'SMSF Advice and Setup',
                href: '/SMSF-Advice-and-Setup',
            },
        ],
    },
    {
        title: 'Client Feedback',
        id: 'Client-Feedback',
        pages: [
            {
                title: 'Client Feedback',
                href: '/client-feedback',
            },
        ],
    },

    {
        title: 'About us',
        id: 'Client-Feedback',
        pages: [
            {
                title: 'Our Process',
                href: '/our-process',
            },
            {
                title: 'D+M Team',
                href: '/dm-team',
            },
            {
                title: 'Talk to Certified Financial Planner',
                href: '/talk-to-a-certified-financial-planner',
            },
            {
                title: ' Independent Financial Advisors & Planners',
                href: '/independent-financial-adviser',
            },
        ],
    },
    {
        title: 'Knowledge Center',
        id: 'Knowledge-Center',
        pages: [
            {
                title: 'Knowledge Center',
                href: 'https://dmfinancialservices.financialknowledgecentre.com.au/kchome.php?page=modules',
            },
        ],
    },
    {
        title: 'Blog',
        id: 'Blog',
        pages: [
            {
                title: 'Blog',
                href: '/blog',
            },
        ],
    },
    {
        title: 'Contact us',
        id: 'Contact-us',
        pages: [
            {
                title: 'Contact us',
                href: '/contact-Us',
            },
        ],
    },
];
