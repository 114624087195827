import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { createSubscribeEmailAction } from "../../../../redux/actions/contentAction";

const Footer = () => {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const allContentReducer = useSelector((state) => state.allContentReducer);

  const FOOTER_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter(
      (data) => data.title === "FOOTER_SECTION"
    );

  /**
 |--------------------------------------------------
 | formik property
 |--------------------------------------------------
 */
  const initialValues = {
    fromMail: "",
  };

  const validationSchema = yup.object({
    fromMail: yup
      .string("Enter your email")
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),
  });

  const onSubmit = (values, formikProps) => {
    values.username = "adminDamien";
    dispatch(createSubscribeEmailAction(values));
    formikProps.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          style={{
            color: "#fff",
          }}
        >
          {FOOTER_SECTION && FOOTER_SECTION[0].desc_1}
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={12} md={4} lg={4}>
        {FOOTER_SECTION && FOOTER_SECTION[0].isShowContent3 && (
          <>
            <Grid container item xs={3}>
              <Typography
                style={{
                  color: "#fff",
                }}
                gutterBottom
              >
                Mobile
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <Typography
                style={{
                  color: "#11B735",
                  outline: "none",
                  textDecoration: "none",
                }}
                gutterBottom
                component="a"
                target="blank"
                href={`tel:${FOOTER_SECTION && FOOTER_SECTION[0].desc_2}`}
              >
                {FOOTER_SECTION && FOOTER_SECTION[0].desc_2}
              </Typography>
            </Grid>
          </>
        )}

        {FOOTER_SECTION && FOOTER_SECTION[0].isShowContent1 && (
          <>
            <Grid container item xs={3}>
              <Typography
                style={{
                  color: "#fff",
                }}
                gutterBottom
              >
                Email
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <Typography
                style={{
                  color: "#11B735",
                  outline: "none",
                  textDecoration: "none",
                }}
                gutterBottom
                component="a"
                target="blank"
                href={`mailto:${FOOTER_SECTION && FOOTER_SECTION[0].desc_3}`}
              >
                {FOOTER_SECTION && FOOTER_SECTION[0].desc_3}
              </Typography>
            </Grid>
          </>
        )}

        {FOOTER_SECTION && FOOTER_SECTION[0].isShowContent2 && (
          <>
            <Grid container item xs={3}>
              <Typography
                style={{
                  color: "#fff",
                }}
                gutterBottom
              >
                Skype
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <Typography
                style={{
                  color: "#11B735",
                  outline: "none",
                  textDecoration: "none",
                }}
                gutterBottom
                component="a"
                target="blank"
                href={`skype:${FOOTER_SECTION && FOOTER_SECTION[0].desc_4}`}
              >
                {FOOTER_SECTION && FOOTER_SECTION[0].desc_4}
              </Typography>
            </Grid>
          </>
        )}

        {FOOTER_SECTION && FOOTER_SECTION[0].isShowContent4 && (
          <Grid container item xs={9}>
            <Typography
              style={{
                color: "#fff",
              }}
              gutterBottom
            >
              {FOOTER_SECTION && FOOTER_SECTION[0].desc_5}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} sm={12} md={4} lg={4}>
        <Grid container item xs={12}>
          {" "}
        </Grid>
        <Grid container item xs={12}>
          {" "}
        </Grid>
        <Grid container item xs={12}>
          <Typography
            style={{
              color: "#fff",
            }}
            gutterBottom
          >
            {FOOTER_SECTION && FOOTER_SECTION[0].desc_6}
            <br />
            {FOOTER_SECTION && FOOTER_SECTION[0].desc_7}
            <br />
            {FOOTER_SECTION && FOOTER_SECTION[0].desc_8}
            <br />
            {FOOTER_SECTION && FOOTER_SECTION[0].desc_9}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        xs={12}
        sm={12}
        md={4}
        lg={4}
      >
        <Grid
          container
          item
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          xs={12}
        >
          <Typography
            style={{
              color: "#fff",
            }}
          >
            {FOOTER_SECTION && FOOTER_SECTION[0].desc_10}
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          xs={12}
        >
          <Typography
            style={{
              color: "#fff",
            }}
            variant="body2"
          >
            {FOOTER_SECTION && FOOTER_SECTION[0].desc_11}
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Box marginBottom={{ xs: 1 }}>
              <TextField
                label="Email *"
                variant="outlined"
                name={"fromMail"}
                margin="dense"
                fullWidth
                placeholder="Your email address"
                value={formik.values.fromMail}
                onChange={formik.handleChange}
                error={
                  formik.touched.fromMail && Boolean(formik.errors.fromMail)
                }
                helperText={formik.touched.fromMail && formik.errors.fromMail}
                style={{ backgroundColor: "#fff" }}
              />
            </Box>
            <Box>
              <Button
                fullWidth
                size={"medium"}
                variant={"contained"}
                type={"submit"}
              >
                {FOOTER_SECTION && FOOTER_SECTION[0].desc_12}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
