import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Video = () => {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const allContentReducer = useSelector((state) => state.allContentReducer);

  const H0ME_2_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter(
      (data) => data.title === "H0ME_2_SECTION"
    );

  return (
    <Box style={{ position: "relative" }} zIndex={8}>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary"}
          align={"center"}
        >
          {H0ME_2_SECTION && H0ME_2_SECTION[0].desc_1}
        </Typography>
      </Box>
      <Box
        component={Typography}
        sx={{ fontWeight: 700 }}
        variant={"h3"}
        gutterBottom
        align={"center"}
      >
        {H0ME_2_SECTION && H0ME_2_SECTION[0].desc_2}
      </Box>

      <Box
        marginTop={2}
        display={"flex"}
        justifyContent={"center"}
        data-aos={"fade-up"}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/BSinYfQF2ps"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Box>

      <Box marginTop={2}>
        <Typography align={"center"} color="textSecondary" variant={"h6"}>
          {H0ME_2_SECTION && H0ME_2_SECTION[0].desc_3}
        </Typography>
      </Box>
      <Box
        marginTop={2}
        display={"flex"}
        justifyContent={"center"}
        data-aos={"fade-up"}
      >
        <Button
          color={"primary"}
          variant={"contained"}
          size={"large"}
          component={Link}
          to="/contact-Us"
        >
          {H0ME_2_SECTION && H0ME_2_SECTION[0].desc_4}
        </Button>
      </Box>
    </Box>
  );
};

export default Video;
