import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

import FormikInput from '../../../common/formik/FormikInput';
import CommonCheckBox from '../../../common/formik/CommonCheckBox';

import { updateHomePost } from '../../../redux/actions/contentAction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Footer = (props) => {
  const { openDialog, handleCloseDialog, description1 } = props;

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const updateHomeContentReducer = useSelector((state) => state.updateHomeContentReducer);

  const { loading } = updateHomeContentReducer;

  const initialValues = {
    desc_1: description1 ? description1.desc_1 : '',
    desc_2: description1 ? description1.desc_2 : '',
    desc_3: description1 ? description1.desc_3 : '',
    desc_4: description1 ? description1.desc_4 : '',
    desc_5: description1 ? description1.desc_5 : '',
    desc_6: description1 ? description1.desc_6 : '',
    desc_7: description1 ? description1.desc_7 : '',
    desc_8: description1 ? description1.desc_8 : '',
    desc_9: description1 ? description1.desc_9 : '',
    desc_10: description1 ? description1.desc_10 : '',
    desc_11: description1 ? description1.desc_11 : '',
    desc_12: description1 ? description1.desc_12 : '',
    username: description1 ? description1.username : '',
    isShowContent1: description1 ? description1.isShowContent1 : true,
    isShowContent2: description1 ? description1.isShowContent2 : true,
    isShowContent3: description1 ? description1.isShowContent3 : true,
    isShowContent4: description1 ? description1.isShowContent4 : true,
  };

  const validationSchema = yup.object().shape({
    desc_1: yup.string().required('Required'),
    desc_2: yup.string().required('Required'),
    desc_3: yup.string().required('Required'),
    desc_4: yup.string().required('Required'),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(updateHomePost(values, description1._id));
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      {description1 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form noValidate>
                <DialogTitle
                  style={{
                    background: 'linear-gradient(to right, #000046, #1cb5e0)',
                    color: '#fff',
                  }}
                >
                  Footer section
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={formik.errors.desc_1 && formik.touched.desc_1}
                        name={'desc_1'}
                        type="text"
                        label={'Company Name'}
                        required
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={2}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{
                        margin: '1rem 0rem',
                      }}
                    >
                      <Grid container item alignItems={'center'} xs={12} sm={4} md={4} lg={4}>
                        <CommonCheckBox name={'isShowContent3'} label={'Show in footer'} />
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <FormikInput
                          error={formik.errors.desc_2 && formik.touched.desc_2}
                          name={'desc_2'}
                          type="text"
                          label={'Mobile'}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      spacing={2}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{
                        margin: '1rem 0rem',
                      }}
                    >
                      <Grid container item alignItems={'center'} xs={12} sm={4} md={4} lg={4}>
                        <CommonCheckBox name={'isShowContent1'} label={'Show in footer'} />
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <FormikInput
                          error={formik.errors.desc_3 && formik.touched.desc_3}
                          name={'desc_3'}
                          type="text"
                          label={'Email'}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{
                        margin: '1rem 0rem',
                      }}
                    >
                      <Grid container item alignItems={'center'} xs={12} sm={4} md={4} lg={4}>
                        <CommonCheckBox name={'isShowContent2'} label={'Show in footer'} />
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <FormikInput
                          error={formik.errors.desc_4 && formik.touched.desc_4}
                          name={'desc_4'}
                          type="text"
                          label={'Skype'}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{
                        margin: '1rem 0rem',
                      }}
                    >
                      <Grid container item alignItems={'center'} xs={12} sm={4} md={4} lg={4}>
                        <CommonCheckBox name={'isShowContent4'} label={'Show in footer'} />
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <FormikInput
                          error={formik.errors.desc_5 && formik.touched.desc_5}
                          name={'desc_5'}
                          type="text"
                          label={'Address'}
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      spacing={1}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: '1rem' }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="subtitle2">Registration information</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_6 && formik.touched.desc_6}
                          name={'desc_6'}
                          type="text"
                          label={'Line 1'}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_7 && formik.touched.desc_7}
                          name={'desc_7'}
                          type="text"
                          label={'Line 2'}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_8 && formik.touched.desc_8}
                          name={'desc_8'}
                          type="text"
                          label={'Line 3'}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_9 && formik.touched.desc_9}
                          name={'desc_9'}
                          type="text"
                          label={'Line 4'}
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      spacing={1}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: '1rem' }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="subtitle2">Email information</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_10 && formik.touched.desc_10}
                          name={'desc_10'}
                          type="text"
                          label={'Title'}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_11 && formik.touched.desc_11}
                          name={'desc_11'}
                          type="text"
                          label={'Subtitle'}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormikInput
                          error={formik.errors.desc_12 && formik.touched.desc_12}
                          name={'desc_12'}
                          type="text"
                          label={'Button name'}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    size={'large'}
                    variant={'outlined'}
                    style={{ color: '#000046', marginRight: 10 }}
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={'large'}
                    variant={'contained'}
                    type={'submit'}
                    style={{ backgroundColor: '#000046', color: '#fff' }}
                  >
                    {loading ? <CircularProgress style={{ color: '#fff' }} size={25} /> : 'Update'}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

export default Footer;
