import * as React from "react";
import { useDispatch } from "react-redux";

import { Redirect, useHistory } from "react-router-dom";
import AOS from "aos";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

import AppsIcon from "@mui/icons-material/Apps";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BookIcon from "@mui/icons-material/Book";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import HomeTable from "./home/HomeTable";
import Services from "./services/Services";
import AboutUs from "./aboutUs/AboutUs";
import ClientFeedback from "./clientFeedback/ClientFeedback";
import ContactUs from "./contactUs/ContactUs";
import FrequentlyQuestion from "./frequentlyQuestion/FrequentlyQuestion";
import EmailDashboard from "./email/EmailDashboard";
import PdfDashboard from "./pdf/PdfDashboard";

import Blog from "./blog/Blog";

import {
  inquiryEmailAction,
  getPdfEmailAction,
  getSubscribeEmailAction,
} from "../redux/actions/contentAction";

import DM_Financial_Services_avatart from "../assets/img/D+M financial services.PNG";

const drawerWidth = 240;

const Dashboard = () => {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(inquiryEmailAction());
    dispatch(getPdfEmailAction());
    dispatch(getSubscribeEmailAction());

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 800,
      easing: "ease-in-out",
    });
  }, []);
  /**
 |--------------------------------------------------
 | check authentication
 |--------------------------------------------------
 */
  const retrievedUserObject =
    localStorage.getItem("dmFinancialToken") &&
    JSON.parse(localStorage.getItem("dmFinancialToken"));

  /**
 |--------------------------------------------------
 | handle log out
 |--------------------------------------------------
 */
  let history = useHistory();

  const handleLogOut = () => {
    localStorage.removeItem("dmFinancialToken");
    history.push("/login");
  };

  /**
 |--------------------------------------------------
 | handle active list, home, services, clientFeedback, aboutUs , contactUs, pdfDashboard
 |--------------------------------------------------
 */
  const [activeList, setActiveList] = React.useState("emailDashboard");

  const handleActiveTab = (name) => {
    setActiveList(name);
  };

  if (retrievedUserObject) {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#2E8BC0",
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              D+M Financial Services
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#101F33",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ height: "100%" }}>
            <Box
              data-aos="flip-left"
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "2rem 0rem",
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={DM_Financial_Services_avatart}
                sx={{ width: 130, height: 130 }}
              />
            </Box>
            <List>
              <ListItem
                data-aos={"fade-right"}
                button
                style={{
                  color: activeList === "emailDashboard" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("emailDashboard")}
              >
                <ListItemIcon>
                  <BlurLinearIcon
                    style={{
                      color:
                        activeList === "emailDashboard" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItem>
              <ListItem
                data-aos={"fade-right"}
                button
                style={{ color: activeList === "home" ? "#4fc3f7" : "#fff" }}
                onClick={() => handleActiveTab("home")}
              >
                <ListItemIcon>
                  <AppsIcon
                    style={{
                      color: activeList === "home" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>

              <ListItem
                data-aos={"fade-right"}
                button
                style={{
                  color: activeList === "services" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("services")}
              >
                <ListItemIcon>
                  <DesignServicesIcon
                    style={{
                      color: activeList === "services" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Services"} />
              </ListItem>

              <ListItem
                data-aos={"fade-right"}
                button
                style={{
                  color: activeList === "clientFeedback" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("clientFeedback")}
              >
                <ListItemIcon>
                  <PersonOutlineIcon
                    style={{
                      color:
                        activeList === "clientFeedback" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Client Feedback"} />
              </ListItem>

              <ListItem
                data-aos={"fade-right"}
                button
                style={{ color: activeList === "aboutUs" ? "#4fc3f7" : "#fff" }}
                onClick={() => handleActiveTab("aboutUs")}
              >
                <ListItemIcon>
                  <GroupWorkIcon
                    style={{
                      color: activeList === "aboutUs" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"About Us"} />
              </ListItem>

              <ListItem
                data-aos={"fade-right"}
                button
                style={{
                  color: activeList === "blog" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("blog")}
              >
                <ListItemIcon>
                  <BookIcon
                    style={{
                      color: activeList === "blog" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Blogs"} />
              </ListItem>

              {/* <ListItem
                data-aos={"fade-right"}
                button
                style={{
                  color: activeList === "contactUs" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("contactUs")}
              >
                <ListItemIcon>
                  <ContactPhoneIcon
                    style={{
                      color: activeList === "contactUs" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Contact Us"} />
              </ListItem> */}

              <ListItem
                data-aos={"fade-right"}
                button
                style={{
                  color:
                    activeList === "frequentlyAskQuestion" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("frequentlyAskQuestion")}
              >
                <ListItemIcon>
                  <HelpOutlineIcon
                    style={{
                      color:
                        activeList === "frequentlyAskQuestion"
                          ? "#4fc3f7"
                          : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Ask Question"} />
              </ListItem>

              <ListItem
                button
                style={{
                  color: activeList === "pdfDashboard" ? "#4fc3f7" : "#fff",
                }}
                onClick={() => handleActiveTab("pdfDashboard")}
              >
                <ListItemIcon>
                  <PictureAsPdfIcon
                    style={{
                      color: activeList === "pdfDashboard" ? "#4fc3f7" : "#fff",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"PDF"} />
              </ListItem>

              <ListItem button onClick={handleLogOut} style={{ color: "#fff" }}>
                <ListItemIcon>
                  <PowerSettingsNewIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText primary={"Log out"} />
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {activeList === "emailDashboard" && <EmailDashboard />}
          {activeList === "home" && <HomeTable />}
          {activeList === "services" && <Services />}
          {activeList === "clientFeedback" && <ClientFeedback />}
          {activeList === "aboutUs" && <AboutUs />}
          {activeList === "contactUs" && <ContactUs />}
          {activeList === "frequentlyAskQuestion" && <FrequentlyQuestion />}
          {activeList === "blog" && <Blog />}
          {activeList === "pdfDashboard" && <PdfDashboard />}
        </Box>
      </Box>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

export default Dashboard;
