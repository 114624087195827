import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

import teamImg3 from "../../../../assets/img/teamImg3.PNG";
import teamImg6 from "../../../../assets/img/teamImg6.PNG";
import teamImg8 from "../../../../assets/img/teamImg8.PNG";
import teamImg9 from "../../../../assets/img/teamImg9.PNG";
import teamImg10 from "../../../../assets/img/teamImg10.PNG";
import teamImg11 from "../../../../assets/img/teamImg11.PNG";
import teamImg12 from "../../../../assets/img/teamImg12.PNG";

const Solutions = () => {
  const theme = useTheme();
  let history = useHistory();

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const allContentReducer = useSelector((state) => state.allContentReducer);

  const H0ME_4_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter(
      (data) => data.title === "H0ME_4_SECTION"
    );

  const H0ME_5_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter(
      (data) => data.title === "H0ME_5_SECTION"
    );

  const handleImage = (index) => {
    switch (index) {
      case 0:
        return teamImg8;
      case 1:
        return teamImg9;
      case 2:
        return teamImg3;
      case 3:
        return teamImg10;
      case 4:
        return teamImg11;
      case 5:
        return teamImg6;
      case 6:
        return teamImg12;

      default:
        return teamImg12;
    }
  };
  return (
    <Box>
      <Box marginBottom={5}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary"}
          align={"center"}
        >
          {H0ME_4_SECTION && H0ME_4_SECTION[0].desc_1}
        </Typography>
        <Typography
          variant="h3"
          align={"center"}
          data-aos={"fade-up"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {H0ME_4_SECTION && H0ME_4_SECTION[0].desc_2}
        </Typography>
        <Typography
          variant="h6"
          align={"center"}
          color={"textSecondary"}
          data-aos={"fade-up"}
        >
          {H0ME_4_SECTION && H0ME_4_SECTION[0].desc_3}
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          {[
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_1,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_2,
              icon: (
                <svg
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.5 16.3333C21.117 16.3333 18.375 18.1606 18.375 20.4167C18.375 22.6727 21.117 24.5 24.5 24.5C27.883 24.5 30.625 26.3273 30.625 28.5833C30.625 30.8394 27.883 32.6667 24.5 32.6667M24.5 16.3333V32.6667M24.5 16.3333C26.7663 16.3333 28.7467 17.1541 29.8063 18.375M24.5 16.3333V14.2917M24.5 32.6667V34.7083M24.5 32.6667C22.2338 32.6667 20.2533 31.8459 19.1937 30.625M42.875 24.5C42.875 26.913 42.3997 29.3025 41.4763 31.5318C40.5529 33.7612 39.1994 35.7868 37.4931 37.4931C35.7868 39.1994 33.7612 40.5529 31.5318 41.4763C29.3025 42.3997 26.913 42.875 24.5 42.875C22.087 42.875 19.6976 42.3997 17.4682 41.4763C15.2388 40.5529 13.2132 39.1994 11.5069 37.4931C9.80064 35.7868 8.44714 33.7612 7.52371 31.5318C6.60028 29.3025 6.125 26.913 6.125 24.5C6.125 19.6266 8.06093 14.9529 11.5069 11.5069C14.9529 8.06093 19.6266 6.125 24.5 6.125C29.3734 6.125 34.0471 8.06093 37.4931 11.5069C40.9391 14.9529 42.875 19.6266 42.875 24.5Z"
                    stroke="#05B62C"
                    stroke-width="4.08333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ),
              path: "/cash-Flow-Management",
            },
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_3,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_4,
              icon: (
                <svg
                  width="44"
                  height="43"
                  viewBox="0 0 44 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.625 12.5417H27.375M27.375 30.4583V25.0833M22 30.4583H22.0179M16.625 30.4583H16.6429M16.625 25.0833H16.6429M22 25.0833H22.0179M27.375 19.7083H27.3929M22 19.7083H22.0179M16.625 19.7083H16.6429M13.0416 37.625H30.9583C31.9087 37.625 32.8201 37.2475 33.4921 36.5755C34.1641 35.9035 34.5416 34.992 34.5416 34.0417V8.95833C34.5416 8.00797 34.1641 7.09654 33.4921 6.42453C32.8201 5.75253 31.9087 5.375 30.9583 5.375H13.0416C12.0913 5.375 11.1799 5.75253 10.5078 6.42453C9.83584 7.09654 9.45831 8.00797 9.45831 8.95833V34.0417C9.45831 34.992 9.83584 35.9035 10.5078 36.5755C11.1799 37.2475 12.0913 37.625 13.0416 37.625Z"
                    stroke="#11B735"
                    stroke-width="3.58333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ),
              path: "/Debt-Advice",
            },
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_5,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_6,
              icon: (
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10H14C12.9391 10 11.9217 10.4214 11.1716 11.1716C10.4214 11.9217 10 12.9391 10 14V38C10 39.0609 10.4214 40.0783 11.1716 40.8284C11.9217 41.5786 12.9391 42 14 42H34C35.0609 42 36.0783 41.5786 36.8284 40.8284C37.5786 40.0783 38 39.0609 38 38V14C38 12.9391 37.5786 11.9217 36.8284 11.1716C36.0783 10.4214 35.0609 10 34 10H30M18 10C18 11.0609 18.4214 12.0783 19.1716 12.8284C19.9217 13.5786 20.9391 14 22 14H26C27.0609 14 28.0783 13.5786 28.8284 12.8284C29.5786 12.0783 30 11.0609 30 10M18 10C18 8.93913 18.4214 7.92172 19.1716 7.17157C19.9217 6.42143 20.9391 6 22 6H26C27.0609 6 28.0783 6.42143 28.8284 7.17157C29.5786 7.92172 30 8.93913 30 10M24 24H30M24 32H30M18 24H18.02M18 32H18.02"
                    stroke="#11B735"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ),
              path: "/Estate-Planning",
            },
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_7,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_8,
              icon: (
                <svg
                  width="51"
                  height="51"
                  viewBox="0 0 51 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34 17V34M25.5 23.375V34M17 29.75V34M12.75 42.5H38.25C39.3772 42.5 40.4582 42.0522 41.2552 41.2552C42.0522 40.4582 42.5 39.3772 42.5 38.25V12.75C42.5 11.6228 42.0522 10.5418 41.2552 9.7448C40.4582 8.94777 39.3772 8.5 38.25 8.5H12.75C11.6228 8.5 10.5418 8.94777 9.7448 9.7448C8.94777 10.5418 8.5 11.6228 8.5 12.75V38.25C8.5 39.3772 8.94777 40.4582 9.7448 41.2552C10.5418 42.0522 11.6228 42.5 12.75 42.5Z"
                    stroke="#0F9D58"
                    stroke-width="4.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ),
              path: "/Investment-Advice",
            },
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_9,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_10,
              icon: (
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M42.0625 26.2339C36.403 28.5223 30.3546 29.695 24.25 29.6875C17.9503 29.6875 11.9396 28.4605 6.4375 26.2339M24.25 23.75H24.2698M32.1667 11.875V7.91671C32.1667 6.86689 31.7496 5.86007 31.0073 5.11774C30.265 4.37541 29.2581 3.95837 28.2083 3.95837H20.2917C19.2419 3.95837 18.235 4.37541 17.4927 5.11774C16.7504 5.86007 16.3333 6.86689 16.3333 7.91671V11.875H32.1667ZM10.3958 39.5834H38.1042C39.154 39.5834 40.1608 39.1663 40.9031 38.424C41.6455 37.6817 42.0625 36.6749 42.0625 35.625V15.8334C42.0625 14.7836 41.6455 13.7767 40.9031 13.0344C40.1608 12.2921 39.154 11.875 38.1042 11.875H10.3958C9.34602 11.875 8.3392 12.2921 7.59687 13.0344C6.85454 13.7767 6.4375 14.7836 6.4375 15.8334V35.625C6.4375 36.6749 6.85454 37.6817 7.59687 38.424C8.3392 39.1663 9.34602 39.5834 10.3958 39.5834Z"
                    stroke="#0F9D58"
                    stroke-width="3.95833"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ),
              path: "/Retirement-Planning",
            },
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_11,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_12,
              icon: (
                <svg
                  width={40}
                  height={40}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              ),
              path: "/SMSF-Advice-and-Setup",
            },
            {
              title: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_13,
              subtitle: H0ME_5_SECTION && H0ME_5_SECTION[0].desc_14,
              icon: (
                <svg
                  width={40}
                  height={40}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              ),
              path: "/Lifestyle-and-Family-Protection",
            },
          ].map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={i}
              onClick={() => history.push(item.path)}
              style={{ cursor: "pointer" }}
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <CardMedia
                      image={handleImage(i)}
                      sx={{
                        height: 240,
                        width: "100%",
                        position: "relative",
                        borderRadius: 10,
                      }}
                    />

                    <Box
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        background: "#fff",
                        opacity: 0.9,
                        height: 65,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 17,
                      }}
                    >
                      <Typography
                        variant={"h6"}
                        gutterBottom
                        style={{
                          textAlign: "center",
                          color: "#000",
                          fontWeight: 600,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </div>
                  <div className="flip-card-back">
                    <Box color={theme.palette.primary.main}>{item.icon}</Box>
                    <Typography color="text.secondary" variant={"body1"}>
                      {item.subtitle}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Solutions;
