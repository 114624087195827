import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Container from "../../common/Container";
import Reviews from "./components/Reviews";
import Hero from "./components/Hero";
import ClientFeedbackModal from "./Modals/ClientFeedbackModal";
import { handleModals } from "../../redux/actions/handleModalAction";

import backgroundImg7 from "../../assets/img/backgroundImg7.png";

const ClientFeedback = () => {
  const theme = useTheme();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const modelReducer = useSelector((state) => state.modalReducer);

  /**
|--------------------------------------------------
| redux integration
|--------------------------------------------------
*/
  const [feedbackDetails, setFeedbackDetails] = React.useState(null);

  const handleModalOpen = (data) => {
    dispatch(handleModals(true, "VIEW_CLIENT_FEEDBACK_MODAL"));
    setFeedbackDetails(data);
  };
  const handleModalClose = () => {
    setFeedbackDetails(null);
    dispatch(handleModals(false, "VIEW_CLIENT_FEEDBACK_MODAL"));
  };

  return (
    <Box>
      <ClientFeedbackModal
        openDialog={modelReducer.VIEW_CLIENT_FEEDBACK_MODAL.modalStatus}
        handleCloseDialog={handleModalClose}
        feedbackDetails={feedbackDetails}
      />
      <Box
        minHeight={{ xs: "auto", md: "calc(35vh - 64px)" }}
        position={"relative"}
        sx={{
          backgroundImage: `url(${backgroundImg7})`,
          backgroundSize: "cover",
          backgroundPosition: "center right",
          zIndex: 10,
          "&:after": {
            position: "absolute",
            content: '" "',
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: theme.palette.common.black,
            opacity: 0.5,
          },
        }}
      >
        <Box
          position={{ xs: "relative", md: "absolute" }}
          top={{ xs: 0, md: "50%" }}
          width={"100%"}
          height={"50%"}
          sx={{
            transform: { md: "translateY(-50%)" },
          }}
          zIndex={2}
        >
          <Container>
            <Hero />
          </Container>
        </Box>
      </Box>
      <Box bgcolor={theme.palette.alternate.main} position={"relative"}>
        <Box
          style={{
            position: "absolute",
            zIndex: 0,
            top: -100,
            right: 0,
          }}
        >
          <svg
            width="446"
            height="527"
            viewBox="0 0 446 527"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="263.5"
              cy="263.5"
              r="263.5"
              fill="url(#paint0_linear_400_167)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_400_167"
                x1="444.404"
                y1="79.7752"
                x2="109.59"
                y2="422.648"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#11B735" />
                <stop offset="1" stop-color="#8AF7A2" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
        <Container position="relative" zIndex={2}>
          <Reviews handleModalOpen={handleModalOpen} />
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
          sx={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            height: "35%",
          }}
        >
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              class="shape-fill"
              fill="#FFFFFF"
              fill-opacity="1"
            ></path>
          </svg>
        </Box>
      </Box>
    </Box>
  );
};

ClientFeedback.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default ClientFeedback;
