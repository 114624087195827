import {
    HANDLE_NOTIFICATION
} from '../types/sharedTypes';

export const notificationReducer = (state = {
    snackText: null,
    snackVariant: null
}, action) => {
    switch (action.type) {
        case HANDLE_NOTIFICATION:
            state = {
                snackText: action.payload.snackText,
                snackVariant: action.payload.snackVariant
            }
            return state;

        default:
            return state;
    }
}
