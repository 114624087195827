import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InquiriesModal = (props) => {
  const { openDialog, handleCloseDialog, description } = props;

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle
        style={{
          background: "linear-gradient(to right, #000046, #1cb5e0)",
          color: "#fff",
        }}
      >
        Inquiries
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ marginTop: "1rem" }}>
          <Grid container item xs={12} sm={6}>
            <TextField
              id="firstName"
              label="First name"
              variant="outlined"
              fullWidth
              value={description && description.firstName}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid container item xs={12} sm={6}>
            <TextField
              id="lastName"
              label="Last name"
              variant="outlined"
              fullWidth
              value={description && description.lastName}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              id="fromMail"
              label="Email"
              variant="outlined"
              fullWidth
              value={description && description.fromMail}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid container item xs={12}>
            <TextField
              id="content"
              label="Message"
              variant="outlined"
              fullWidth
              value={description && description.content}
              InputLabelProps={{ shrink: true }}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size={"large"}
          variant={"contained"}
          style={{ backgroundColor: "#000046", color: "#fff" }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InquiriesModal;
