export const variantType = [
    {
        id: "body1",
        name: "body1",
    },
    {
        id: "body2",
        name: "body2",
    },
    {
        id: "button",
        name: "button",
    },
    {
        id: "caption",
        name: "caption",
    },
    {
        id: "h1",
        name: "h1",
    },
    {
        id: "h2",
        name: "h2",
    },
    {
        id: "h3",
        name: "h3",
    },
    {
        id: "h4",
        name: "h4",
    },
    {
        id: "h5",
        name: "h5",
    },
    {
        id: "h6",
        name: "h6",
    },
    {
        id: "inherit",
        name: "inherit",
    },
    {
        id: "overline",
        name: "overline",
    },
    {
        id: "subtitle1",
        name: "subtitle1",
    },
    {
        id: "subtitle2",
        name: "subtitle2",
    },
    {
        id: "string",
        name: "string",
    },
];

export const fontColorType = [
    {
        id: "text.secondary",
        name: "text.secondary",
    },
    {
        id: "primary",
        name: "primary",
    },
    {
        id: "textPrimary",
        name: "textPrimary",
    },
    {
        id: "textSecondary",
        name: "textSecondary",
    },
];