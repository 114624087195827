import React from "react";
import { Field } from "formik";

const CommonCheckBox = (props) => {
  const { label: Label, name, disabled } = props;
  return (
    <label>
      <Field type="checkbox" name={name} disabled={disabled} />
      {Label}
    </label>
  );
};

export default CommonCheckBox;
