
import {
    GET_ALL_CONTENT_REQUEST,
    GET_ALL_CONTENT_SUCCESS,
    GET_ALL_CONTENT_ERROR,

    UPDATE_HOME_CONTENT_REQUEST,
    UPDATE_HOME_CONTENT_SUCCESS,
    UPDATE_HOME_CONTENT_ERROR,

    GET_ALL_Frequently_Questions_REQUEST,
    GET_ALL_Frequently_Questions_SUCCESS,
    GET_ALL_Frequently_Questions_ERROR,

    UPDATE_Frequently_Questions_REQUEST,
    UPDATE_Frequently_Questions_SUCCESS,
    UPDATE_Frequently_Questions_ERROR,

    GET_ALL_ABOUT_US_REQUEST,
    GET_ALL_ABOUT_US_SUCCESS,
    GET_ALL_ABOUT_US_ERROR,

    UPDATE_ABOUT_US_REQUEST,
    UPDATE_ABOUT_US_SUCCESS,
    UPDATE_ABOUT_US_ERROR,

    GET_ALL_BLOG_POST_REQUEST,
    GET_ALL_BLOG_POST_SUCCESS,
    GET_ALL_BLOG_POST_ERROR,

    UPDATE_BLOG_POST_REQUEST,
    UPDATE_BLOG_POST_SUCCESS,
    UPDATE_BLOG_POST_ERROR,

    CREATE_BLOG_POST_REQUEST,
    CREATE_BLOG_POST_SUCCESS,
    CREATE_BLOG_POST_ERROR,

    GET_DM_TEAM_DETAILS_REQUEST,
    GET_DM_TEAM_DETAILS_SUCCESS,
    GET_DM_TEAM_DETAILS_ERROR,

    UPDATE_DM_TEAM_DETAILS_REQUEST,
    UPDATE_DM_TEAM_DETAILS_SUCCESS,
    UPDATE_DM_TEAM_DETAILS_ERROR,

    GET_CASH_FLOW_MANAGEMENT_REQUEST,
    GET_CASH_FLOW_MANAGEMENT_SUCCESS,
    GET_CASH_FLOW_MANAGEMENT_ERROR,

    UPDATE_CASH_FLOW_MANAGEMENT_REQUEST,
    UPDATE_CASH_FLOW_MANAGEMENT_SUCCESS,
    UPDATE_CASH_FLOW_MANAGEMENT_ERROR,

    GET_OUR_PROCESS_REQUEST,
    GET_OUR_PROCESS_SUCCESS,
    GET_OUR_PROCESS_ERROR,

    UPDATE_OUR_PROCESS_REQUEST,
    UPDATE_OUR_PROCESS_SUCCESS,
    UPDATE_OUR_PROCESS_ERROR,

    GET_CERTIFIED_FINANCIAL_PLANNER_REQUEST,
    GET_CERTIFIED_FINANCIAL_PLANNER_SUCCESS,
    GET_CERTIFIED_FINANCIAL_PLANNER_ERROR,

    UPDATE_CERTIFIED_FINANCIAL_PLANNER_REQUEST,
    UPDATE_CERTIFIED_FINANCIAL_PLANNER_SUCCESS,
    UPDATE_CERTIFIED_FINANCIAL_PLANNER_ERROR,

    GET_CLIENT_FEEDBACK_REQUEST,
    GET_CLIENT_FEEDBACK_SUCCESS,
    GET_CLIENT_FEEDBACK_ERROR,

    CREATE_CLIENT_FEEDBACK_REQUEST,
    CREATE_CLIENT_FEEDBACK_SUCCESS,
    CREATE_CLIENT_FEEDBACK_ERROR,

    UPDATE_CLIENT_FEEDBACK_REQUEST,
    UPDATE_CLIENT_FEEDBACK_SUCCESS,
    UPDATE_CLIENT_FEEDBACK_ERROR,

    CREATE_INQUIRY_MAIL_REQUEST,
    CREATE_INQUIRY_MAIL_SUCCESS,
    CREATE_INQUIRY_MAIL_ERROR,

    INQUIRY_MAIL_REQUEST,
    INQUIRY_MAIL_SUCCESS,
    INQUIRY_MAIL_ERROR,

    UPDATE_INQUIRY_MAIL_REQUEST,
    UPDATE_INQUIRY_MAIL_SUCCESS,
    UPDATE_INQUIRY_MAIL_ERROR,

    DELETE_INQUIRY_MAIL_REQUEST,
    DELETE_INQUIRY_MAIL_SUCCESS,
    DELETE_INQUIRY_MAIL_ERROR,

    CREATE_PDF_MAIL_REQUEST,
    CREATE_PDF_MAIL_SUCCESS,
    CREATE_PDF_MAIL_ERROR,

    GET_PDF_MAIL_REQUEST,
    GET_PDF_MAIL_SUCCESS,
    GET_PDF_MAIL_ERROR,

    UPDATE_PDF_MAIL_REQUEST,
    UPDATE_PDF_MAIL_SUCCESS,
    UPDATE_PDF_MAIL_ERROR,

    DELETE_PDF_MAIL_REQUEST,
    DELETE_PDF_MAIL_SUCCESS,
    DELETE_PDF_MAIL_ERROR,

    CREATE_SUBSCRIBE_MAIL_REQUEST,
    CREATE_SUBSCRIBE_MAIL_SUCCESS,
    CREATE_SUBSCRIBE_MAIL_ERROR,

    SUBSCRIBE_MAIL_REQUEST,
    SUBSCRIBE_MAIL_SUCCESS,
    SUBSCRIBE_MAIL_ERROR,

    UPDATE_SUBSCRIBE_MAIL_REQUEST,
    UPDATE_SUBSCRIBE_MAIL_SUCCESS,
    UPDATE_SUBSCRIBE_MAIL_ERROR,

    DELETE_SUBSCRIBE_MAIL_REQUEST,
    DELETE_SUBSCRIBE_MAIL_SUCCESS,
    DELETE_SUBSCRIBE_MAIL_ERROR,
} from "../types/contentTypes";
import { API_URL } from "../../utils/API_URLS";
import axios from "axios";
import api from "../../utils/api"
import { handleModals } from "../actions/handleModalAction"
import { handleNotification } from "../actions/handleNotificationAction"


/**
|--------------------------------------------------
| get all home post
|--------------------------------------------------
*/
export const getAllPost = () => async dispatch => {

    dispatch({
        type: GET_ALL_CONTENT_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getAllContent);

        dispatch({
            type: GET_ALL_CONTENT_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ALL_CONTENT_ERROR
        });
    }
};


/**
|--------------------------------------------------
| update home post
|--------------------------------------------------
*/
export const updateHomePost = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_HOME_CONTENT_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateHomeContent.replace("{id}", id), body);

        dispatch(getAllPost())
        dispatch(handleModals(false, "H0ME_1_SECTION_MODAL"))
        dispatch(handleModals(false, "H0ME_2_SECTION_MODAL"))
        dispatch(handleModals(false, "H0ME_3_SECTION_MODAL"))
        dispatch(handleModals(false, "H0ME_4_SECTION_MODAL"))
        dispatch(handleModals(false, "H0ME_5_SECTION_MODAL"))
        dispatch(handleModals(false, "H0ME_6_SECTION_MODAL"))
        dispatch(handleModals(false, "H0ME_FOOTER_SECTION_MODAL"))

        dispatch({
            type: UPDATE_HOME_CONTENT_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Content is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_HOME_CONTENT_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get all Frequently ask Questions
|--------------------------------------------------
*/
export const getAllFrequentlyQuestions = () => async dispatch => {

    dispatch({
        type: GET_ALL_Frequently_Questions_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getAllFrequentlyQuestions);

        dispatch({
            type: GET_ALL_Frequently_Questions_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ALL_Frequently_Questions_ERROR
        });
    }
};


/**
|--------------------------------------------------
| update home post
|--------------------------------------------------
*/
export const updateFrequentlyQuestions = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_Frequently_Questions_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateFrequentlyQuestions.replace("{id}", id), body);

        dispatch(getAllFrequentlyQuestions())
        dispatch(handleModals(false, "QUESTION_MODAL"))

        dispatch({
            type: UPDATE_Frequently_Questions_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Frequently asked question is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_Frequently_Questions_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get all about us
|--------------------------------------------------
*/
export const getAllAboutUsAction = () => async dispatch => {

    dispatch({
        type: GET_ALL_ABOUT_US_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getAllAboutUs);

        dispatch({
            type: GET_ALL_ABOUT_US_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ALL_ABOUT_US_ERROR
        });
    }
};

/**
|--------------------------------------------------
| update about us independent financial advisors and planners
|--------------------------------------------------
*/
export const updateAboutUsAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_ABOUT_US_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateAboutUs.replace("{id}", id), body);

        dispatch(getAllAboutUsAction())
        dispatch(handleModals(false, "ADVISORS_AND_PLANNERS_MODAL"))

        dispatch({
            type: UPDATE_ABOUT_US_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_ABOUT_US_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get all about us
|--------------------------------------------------
*/
export const getAllBlogPostAction = () => async dispatch => {

    dispatch({
        type: GET_ALL_BLOG_POST_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getAllBlogPost);

        dispatch({
            type: GET_ALL_BLOG_POST_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ALL_BLOG_POST_ERROR
        });
    }
};


/**
|--------------------------------------------------
| update blog post
|--------------------------------------------------
*/
export const updateBlogsPost = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_BLOG_POST_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateBlogPost.replace("{id}", id), body);

        dispatch(getAllBlogPostAction())
        dispatch(handleModals(false, "BLOGS_MODAL"))

        dispatch({
            type: UPDATE_BLOG_POST_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_BLOG_POST_ERROR
        });
    }
};

/**
|--------------------------------------------------
| create blog post
|--------------------------------------------------
*/
export const createBlogsPost = (payload) => async dispatch => {

    dispatch({
        type: CREATE_BLOG_POST_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.post(API_URL.createBlogPost, body);

        dispatch(getAllBlogPostAction())
        dispatch(handleModals(false, "BLOG_NEW_MODAL"))

        dispatch({
            type: CREATE_BLOG_POST_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is created successfully", "success"));

    } catch (err) {
        dispatch({
            type: CREATE_BLOG_POST_ERROR
        });
    }
};

/**
|--------------------------------------------------
| get all dm team post
|--------------------------------------------------
*/
export const getDmTeamPostAction = () => async dispatch => {

    dispatch({
        type: GET_DM_TEAM_DETAILS_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getDmTeamPost);

        dispatch({
            type: GET_DM_TEAM_DETAILS_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_DM_TEAM_DETAILS_ERROR
        });
    }
};

/**
|--------------------------------------------------
| update dm team post
|--------------------------------------------------
*/
export const updateDmTeamPostAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_DM_TEAM_DETAILS_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateDmTeamPost.replace("{id}", id), body);

        dispatch(getDmTeamPostAction())
        dispatch(handleModals(false, "DM_TEAM_MODAL"))

        dispatch({
            type: UPDATE_DM_TEAM_DETAILS_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_DM_TEAM_DETAILS_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get all dm team post
|--------------------------------------------------
*/
export const getCashFlowPostAction = () => async dispatch => {

    dispatch({
        type: GET_CASH_FLOW_MANAGEMENT_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getCashFlowPost);

        dispatch({
            type: GET_CASH_FLOW_MANAGEMENT_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_CASH_FLOW_MANAGEMENT_ERROR
        });
    }
};

/**
|--------------------------------------------------
| update dm team post
|--------------------------------------------------
*/
export const updateCashFlowPostAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_CASH_FLOW_MANAGEMENT_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateGetCashFlowPost.replace("{id}", id), body);

        dispatch(getCashFlowPostAction())
        dispatch(handleModals(false, "CASH_FLOW_MODAL"))

        dispatch({
            type: UPDATE_CASH_FLOW_MANAGEMENT_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_CASH_FLOW_MANAGEMENT_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get about us our process
|--------------------------------------------------
*/
export const getOurProcessPostAction = () => async dispatch => {

    dispatch({
        type: GET_OUR_PROCESS_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getOurProcessPost);

        dispatch({
            type: GET_OUR_PROCESS_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_OUR_PROCESS_ERROR
        });
    }
};

/**
|--------------------------------------------------
| update  about us our process
|--------------------------------------------------
*/
export const updateOurProcessPostAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_OUR_PROCESS_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateOurProcessPost.replace("{id}", id), body);

        dispatch(getOurProcessPostAction())
        dispatch(handleModals(false, "OUR_PROCESS_MODAL"))

        dispatch({
            type: UPDATE_OUR_PROCESS_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_OUR_PROCESS_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get certified financial planner post in about us
|--------------------------------------------------
*/
export const getCertifiedFinancialPlannerPostAction = () => async dispatch => {

    dispatch({
        type: GET_CERTIFIED_FINANCIAL_PLANNER_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getCertifiedFinancialPlannerPost);

        dispatch({
            type: GET_CERTIFIED_FINANCIAL_PLANNER_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_CERTIFIED_FINANCIAL_PLANNER_ERROR
        });
    }
};

/**
|--------------------------------------------------
| update  certified financial planner post in about us
|--------------------------------------------------
*/
export const updateCertifiedFinancialPlannerPostAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_CERTIFIED_FINANCIAL_PLANNER_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateCertifiedFinancialPlannerPost.replace("{id}", id), body);

        dispatch(getCertifiedFinancialPlannerPostAction())
        dispatch(handleModals(false, "CERTIFIED_FINANCIAL_PLANNER_MODAL"))

        dispatch({
            type: UPDATE_CERTIFIED_FINANCIAL_PLANNER_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_CERTIFIED_FINANCIAL_PLANNER_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get Client feedback post 
|--------------------------------------------------
*/
export const getClientFeedbackPostAction = () => async dispatch => {

    dispatch({
        type: GET_CLIENT_FEEDBACK_REQUEST,
    });

    try {
        const res = await api.get(API_URL.getClientFeedbackPost);

        dispatch({
            type: GET_CLIENT_FEEDBACK_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_CLIENT_FEEDBACK_ERROR
        });
    }
};

/**
|--------------------------------------------------
| update client feedback post 
|--------------------------------------------------
*/
export const updateClientFeedbackPostAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_CLIENT_FEEDBACK_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateClientFeedbackPost.replace("{id}", id), body);

        dispatch(getClientFeedbackPostAction())
        dispatch(handleModals(false, "CLIENT_FEEDBACK_MODAL"))

        dispatch({
            type: UPDATE_CLIENT_FEEDBACK_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is updated successfully", "success"));

    } catch (err) {
        dispatch({
            type: UPDATE_CLIENT_FEEDBACK_ERROR
        });
    }
};

/**
|--------------------------------------------------
| create client feedback post 
|--------------------------------------------------
*/
export const createClientFeedbackPostAction = (payload,) => async dispatch => {

    dispatch({
        type: CREATE_CLIENT_FEEDBACK_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.post(API_URL.createClientFeedbackPost, body);

        dispatch(getClientFeedbackPostAction())
        dispatch(handleModals(false, "CREATE_CLIENT_FEEDBACK_MODAL"))

        dispatch({
            type: CREATE_CLIENT_FEEDBACK_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Post is created successfully", "success"));

    } catch (err) {
        dispatch({
            type: CREATE_CLIENT_FEEDBACK_ERROR
        });
    }
};



/**
|--------------------------------------------------
| create inquiry email
|--------------------------------------------------
*/
export const createInquiryEmailAction = (payload) => async dispatch => {

    dispatch({
        type: CREATE_INQUIRY_MAIL_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.post(API_URL.createInquiryMail, body);

        dispatch({
            type: CREATE_INQUIRY_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Email is send successfully.", "success"));
        dispatch(inquiryEmailAction())

    } catch (err) {
        dispatch({
            type: CREATE_INQUIRY_MAIL_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get inquiry email
|--------------------------------------------------
*/
export const inquiryEmailAction = (payload) => async dispatch => {

    dispatch({
        type: INQUIRY_MAIL_REQUEST,
    });
    try {
        const res = await api.get(API_URL.getInquiryMail);

        dispatch({
            type: INQUIRY_MAIL_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: INQUIRY_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| Update inquiry email
|--------------------------------------------------
*/
export const updateInquiryEmailAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_INQUIRY_MAIL_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateInquiryMail.replace("{id}", id), body);

        dispatch({
            type: UPDATE_INQUIRY_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Status is updated successfully", "success"));

        dispatch(inquiryEmailAction())

    } catch (err) {
        dispatch({
            type: UPDATE_INQUIRY_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| delete inquiry email
|--------------------------------------------------
*/
export const deleteInquiryEmailAction = (payload, id) => async dispatch => {

    dispatch({
        type: DELETE_INQUIRY_MAIL_REQUEST,
    });

    try {
        const res = await api.delete(API_URL.deleteInquiryMail.replace("{id}", id), {
            data: { username: payload.username },
        });

        dispatch({
            type: DELETE_INQUIRY_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Item is deleted successfully", "success"));
        dispatch(handleModals(false, "CONFIRMATION_MODAL"));
        dispatch(inquiryEmailAction())

    } catch (err) {
        dispatch({
            type: DELETE_INQUIRY_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| create get pdf
|--------------------------------------------------
*/
export const createGetPdfEmailAction = (payload) => async dispatch => {

    dispatch({
        type: CREATE_PDF_MAIL_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.post(API_URL.createRequestPdfMail, body);

        dispatch({
            type: CREATE_PDF_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Email is send successfully.", "success"));
        dispatch(getPdfEmailAction())

    } catch (err) {
        dispatch({
            type: CREATE_PDF_MAIL_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get pdf email
|--------------------------------------------------
*/
export const getPdfEmailAction = (payload) => async dispatch => {

    dispatch({
        type: GET_PDF_MAIL_REQUEST,
    });
    try {
        const res = await api.get(API_URL.getRequestPdfMail);

        dispatch({
            type: GET_PDF_MAIL_SUCCESS,
            payload: res.data
        });


    } catch (err) {
        dispatch({
            type: GET_PDF_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| Update get pdf mail
|--------------------------------------------------
*/
export const updateRequestPdfEmailAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_PDF_MAIL_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateRequestPdfMail.replace("{id}", id), body);

        dispatch({
            type: UPDATE_PDF_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Status is updated successfully", "success"));

        dispatch(getPdfEmailAction())

    } catch (err) {
        dispatch({
            type: UPDATE_PDF_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| delete get pdf mail
|--------------------------------------------------
*/
export const deleteRequestPdfEmailAction = (payload, id) => async dispatch => {

    dispatch({
        type: DELETE_PDF_MAIL_REQUEST,
    });
    try {

        const res = await api.delete(API_URL.deleteRequestPdfMail.replace("{id}", id), {
            data: { username: payload.username },
        });

        dispatch({
            type: DELETE_PDF_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Item is deleted successfully", "success"));
        dispatch(getPdfEmailAction())
        dispatch(handleModals(false, "CONFIRMATION_MODAL"));


    } catch (err) {
        dispatch({
            type: DELETE_PDF_MAIL_ERROR
        });
    }
};



/**
|--------------------------------------------------
| create Subscribe Email
|--------------------------------------------------
*/
export const createSubscribeEmailAction = (payload) => async dispatch => {

    dispatch({
        type: CREATE_SUBSCRIBE_MAIL_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.post(API_URL.createSubscribeMail, body);

        dispatch({
            type: CREATE_SUBSCRIBE_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Email is send successfully.", "success"));
        dispatch(getSubscribeEmailAction())

    } catch (err) {
        dispatch({
            type: CREATE_SUBSCRIBE_MAIL_ERROR
        });
    }
};


/**
|--------------------------------------------------
| get Subscribe mail
|--------------------------------------------------
*/
export const getSubscribeEmailAction = (payload) => async dispatch => {

    dispatch({
        type: SUBSCRIBE_MAIL_REQUEST,
    });
    try {
        const res = await api.get(API_URL.getSubscribeMail);

        dispatch({
            type: SUBSCRIBE_MAIL_SUCCESS,
            payload: res.data
        });


    } catch (err) {
        dispatch({
            type: SUBSCRIBE_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| Update Subscribe mail
|--------------------------------------------------
*/
export const updateSubscribeEmailAction = (payload, id) => async dispatch => {

    dispatch({
        type: UPDATE_SUBSCRIBE_MAIL_REQUEST,
    });
    const body = JSON.stringify(payload);

    try {
        const res = await api.put(API_URL.updateSubscribeMail.replace("{id}", id), body);

        dispatch({
            type: UPDATE_SUBSCRIBE_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Status is updated successfully", "success"));

        dispatch(getSubscribeEmailAction())

    } catch (err) {
        dispatch({
            type: UPDATE_SUBSCRIBE_MAIL_ERROR
        });
    }
};

/**
|--------------------------------------------------
| delete Subscribe mail
|--------------------------------------------------
*/
export const deleteSubscribeEmailAction = (payload, id) => async dispatch => {

    dispatch({
        type: DELETE_SUBSCRIBE_MAIL_REQUEST,
    });
    try {
        const res = await api.delete(API_URL.deleteSubscribeMail.replace("{id}", id), {
            data: { username: payload.username },
        });

        dispatch({
            type: DELETE_SUBSCRIBE_MAIL_SUCCESS,
            payload: res.data
        });
        dispatch(handleNotification("Item is deleted successfully", "success"));

        dispatch(getSubscribeEmailAction())

        dispatch(handleModals(false, "CONFIRMATION_MODAL"));

    } catch (err) {
        dispatch({
            type: DELETE_SUBSCRIBE_MAIL_ERROR
        });
    }
};







