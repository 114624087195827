import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Content = () => {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */

  const certifiedFinancialPlannerPostReducer = useSelector(
    (state) => state.certifiedFinancialPlannerPostReducer
  );

  const { post } = certifiedFinancialPlannerPostReducer;

  return (
    <Grid container spacing={2}>
      {post.length !== 0 &&
        post[0].description.map((item, i) => (
          <Grid item xs={12} key={i} data-aos={"fade-up"}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={i}
                style={{
                  background:
                    i % 3 === 0
                      ? "linear-gradient(to right, #2193b0, #6dd5ed)"
                      : i % 2 === 0
                      ? "linear-gradient(to right, #ec6f66, #f3a183)"
                      : "linear-gradient(to right, #11998e, #38ef7d",
                  color: "#ffffff",
                }}
              >
                <Typography variant={"body1"} gutterBottom>
                  {item.description}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.description2}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
    </Grid>
  );
};

export default Content;
