import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import avatarImg from "../../../assets/img/avaratImg.png";
import Typography from "@mui/material/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientFeedbackModal = (props) => {
  const { openDialog, handleCloseDialog, feedbackDetails } = props;

  const theme = useTheme();

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle
        style={{
          background: "linear-gradient(to right, #11998e, #38ef7d)",
          color: "#fff",
        }}
      >
        Client Feedback
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item xs={12}>
            <Box padding={{ xs: 1, sm: 2, lg: 3 }}>
              <Box display={"flex"} justifyContent={"center"}>
                <Avatar
                  alt={feedbackDetails && feedbackDetails.name}
                  src={avatarImg}
                  sx={{
                    width: 100,
                    height: 100,
                    zIndex: 10,
                    boxShadow: "#11B735 0px 1px 10px",
                  }}
                />
              </Box>
              <Box
                component={ListItem}
                disableGutters
                width={"auto"}
                padding={0}
              >
                <Box
                  // display={"flex"}
                  // justifyContent={"center"}
                  textAlign={"center"}
                  component={ListItemText}
                  primary={feedbackDetails && feedbackDetails.name}
                  secondary={feedbackDetails && feedbackDetails.title}
                  margin={0}
                />
              </Box>
              <Box marginBottom={1}>
                {[1, 2, 3, 4, 5].map((feedbackDetails, i) => (
                  <Box
                    key={i}
                    color={theme.palette.secondary.main}
                    display={"inline"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      width={24}
                      height={24}
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </Box>
                ))}
              </Box>
              <Box
                component={Typography}
                variant={"body1"}
                fontWeight={400}
                marginBottom={2}
              >
                {feedbackDetails && feedbackDetails.feedback}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          background: "linear-gradient(to right, #11998e, #38ef7d)",
          color: "#fff",
        }}
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Typography>D+M Financial Services</Typography>
          <Button
            size={"large"}
            variant={"contained"}
            type={"submit"}
            style={{
              backgroundColor: "#fff",
              color: "#000046",
              marginRight: 5,
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ClientFeedbackModal;
