import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";

import FormikInput from "../../../common/formik/FormikInput";

import { updateClientFeedbackPostAction } from "../../../redux/actions/contentAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateClientFeedback = (props) => {
  const { openDialog, handleCloseDialog, description1 } = props;

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const updateClientFeedbackReducer = useSelector(
    (state) => state.updateClientFeedbackReducer
  );

  const { loading } = updateClientFeedbackReducer;

  const initialValues = {
    username: description1 ? description1.username : "",
    title: description1 ? description1.title : "",
    feedback: description1 ? description1.feedback : "",
    name: description1 ? description1.name : "",
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Required"),
    feedback: yup.string().required("Required"),
    name: yup.string().required("Required"),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(updateClientFeedbackPostAction(values, description1._id));
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      {description1 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form noValidate>
                <DialogTitle
                  style={{
                    background: "linear-gradient(to right, #000046, #1cb5e0)",
                    color: "#fff",
                  }}
                >
                  Update client feedback
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1} style={{ marginTop: "2rem" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={formik.errors.name && formik.touched.name}
                        name={"name"}
                        type="text"
                        label={"Name"}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={formik.errors.title && formik.touched.title}
                        name={"title"}
                        type="text"
                        label={"Occupation"}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={
                          formik.errors.feedback && formik.touched.feedback
                        }
                        name={"feedback"}
                        type="text"
                        label={"Feedback"}
                        required
                        multiline
                        rows={10}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    size={"large"}
                    variant={"outlined"}
                    style={{ color: "#000046", marginRight: 10 }}
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={"large"}
                    variant={"contained"}
                    type={"submit"}
                    style={{ backgroundColor: "#000046", color: "#fff" }}
                  >
                    {loading ? (
                      <CircularProgress style={{ color: "#fff" }} size={25} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

export default UpdateClientFeedback;
