import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const About = () => {
  let history = useHistory();
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const allContentReducer = useSelector((state) => state.allContentReducer);

  const H0ME_6_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter(
      (data) => data.title === "H0ME_6_SECTION"
    );

  return (
    <Box>
      <Box>
        <Typography
          variant="h6"
          align={"center"}
          color={"textSecondary"}
          data-aos={"fade-up"}
          marginBottom={2}
          sx={{
            fontWeight: "500",
          }}
        >
          {H0ME_6_SECTION && H0ME_6_SECTION[0].desc_1}
        </Typography>

        <Typography
          variant="h6"
          align={"center"}
          data-aos={"fade-up"}
          marginBottom={2}
          sx={{
            fontWeight: "500",
          }}
        >
          {H0ME_6_SECTION && H0ME_6_SECTION[0].desc_2}
        </Typography>
      </Box>
      <Box
        marginTop={2}
        display={"flex"}
        justifyContent={"center"}
        data-aos={"fade-up"}
        onClick={() => history.push("/contact-Us")}
      >
        <Button color={"primary"} variant={"contained"} size={"large"}>
          {H0ME_6_SECTION && H0ME_6_SECTION[0].desc_3}
        </Button>
      </Box>
      <Box>
        <Typography
          variant="body2"
          align={"center"}
          color={"textSecondary"}
          data-aos={"fade-up"}
          marginTop={1}
        >
          {H0ME_6_SECTION && H0ME_6_SECTION[0].desc_4}
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
