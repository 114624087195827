import React from "react";
import { FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import FormikInput from "../../../common/formik/FormikInput";
import FormikSelect from "../../../common/formik/FormikSelect";
import CommonCheckBox from "../../../common/formik/CommonCheckBox";

import { updateCashFlowPostAction } from "../../../redux/actions/contentAction";

import { variantType, fontColorType } from "../../../utils/dropDownData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServicesModal = (props) => {
  const { openDialog, handleCloseDialog, description1 } = props;

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const updateCashFlowPostReducer = useSelector(
    (state) => state.updateCashFlowPostReducer
  );

  const { loading } = updateCashFlowPostReducer;

  const initialValues = {
    username: description1 ? description1.username : "",
    title: description1 ? description1.title : "",
    title2: description1 ? description1.title2 : "",
    cardDescription: description1 ? description1.cardDescription : "",
    caseStudiesDescription: description1
      ? description1.caseStudiesDescription
      : "",
    mainDescription: description1 ? description1.mainDescription : "",
    secondaryDescription: description1
      ? description1.secondaryDescription
      : [
          {
            variant: "h6",
            description: "",
            fontWeight: 400,
            isBr: true,
          },
        ],
    situationDescription: description1
      ? description1.situationDescription
      : [
          {
            variant: "",
            description: "",
            fontWeight: "",
            isBr: false,
            color: "",
          },
        ],
    solutionDescription: description1
      ? description1.solutionDescription
      : [
          {
            variant: "",
            description: "",
            fontWeight: "",
            isBr: false,
            color: "",
          },
        ],
  };

  const validationSchema = yup.object().shape({
    title2: yup.string().required("Required"),
    cardDescription: yup.string().required("Required"),
    caseStudiesDescription: yup.string().required("Required"),
    mainDescription: yup.string().required("Required"),
  });

  // handle add or remove secondary description arr
  const [secondaryDescriptionArr, setSecondaryDescriptionArr] = React.useState([
    0,
  ]);

  // handle add or remove case situation description arr
  const [situationDescriptionArr, setSituationDescriptionArr] = React.useState([
    0,
  ]);

  // handle add or remove case solution description arr
  const [solutionDescriptionArr, setSolutionDescriptionArr] = React.useState([
    0,
  ]);

  React.useEffect(() => {
    description1 &&
      setSecondaryDescriptionArr(description1.secondaryDescription);

    description1 &&
      setSituationDescriptionArr(description1.situationDescription);

    description1 && setSolutionDescriptionArr(description1.solutionDescription);
  }, [openDialog]);

  const onSubmit = (values, submitProps) => {
    dispatch(updateCashFlowPostAction(values, description1._id));
  };

  /**
 |--------------------------------------------------
 | handle expand and state
 |--------------------------------------------------
 */

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      {description1 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form noValidate>
                <DialogTitle
                  style={{
                    background: "linear-gradient(to right, #000046, #1cb5e0)",
                    color: "#fff",
                  }}
                >
                  Cash-flow Management
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={formik.errors.title2 && formik.touched.title2}
                        name={"title2"}
                        type="text"
                        label={"Title"}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        style={{ backgroundColor: "#F7F9FF" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Description
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormikInput
                              error={
                                formik.errors.mainDescription &&
                                formik.touched.mainDescription
                              }
                              name={"mainDescription"}
                              type="text"
                              label={"Main Description"}
                              required
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FieldArray name={"secondaryDescription"}>
                              {(fieldArrayProps) => {
                                const { push, remove, form } = fieldArrayProps;
                                const { values } = form;
                                const { secondaryDescription } = values;

                                return (
                                  <div style={{ width: "100%" }}>
                                    {secondaryDescription &&
                                      secondaryDescription.map(
                                        (secondaryDescription, index) => (
                                          <Grid
                                            container
                                            spacing={1}
                                            key={index}
                                          >
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              style={{ marginTop: "2rem" }}
                                              justifyContent={"flex-end"}
                                            >
                                              <AddCircleOutlineIcon
                                                style={{
                                                  color: "#33CC70",
                                                  fontSize: "2rem",
                                                }}
                                                onClick={() => {
                                                  return (
                                                    push({
                                                      variant: "",
                                                      description: "",
                                                      fontWeight: 400,
                                                      isBr: false,
                                                    }),
                                                    secondaryDescriptionArr.push(
                                                      1
                                                    ),
                                                    setSecondaryDescriptionArr(
                                                      secondaryDescriptionArr
                                                    )
                                                  );
                                                }}
                                              />

                                              {secondaryDescriptionArr.length !==
                                                1 && (
                                                <DeleteOutlineIcon
                                                  style={{
                                                    color: "red",
                                                    fontSize: "2rem",
                                                  }}
                                                  onClick={() => {
                                                    return secondaryDescriptionArr.length ===
                                                      1
                                                      ? null
                                                      : (secondaryDescriptionArr.pop(
                                                          index
                                                        ),
                                                        setSecondaryDescriptionArr(
                                                          secondaryDescriptionArr
                                                        ),
                                                        remove(index));
                                                  }}
                                                />
                                              )}
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikSelect
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .secondaryDescription &&
                                                  formik.touched
                                                    .secondaryDescription &&
                                                  formik.errors
                                                    .secondaryDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .secondaryDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .secondaryDescription[index]
                                                    .variant &&
                                                  formik.touched
                                                    .secondaryDescription[index]
                                                    .variant
                                                }
                                                name={`secondaryDescription[${index}].variant`}
                                                options={variantType}
                                                label={"Font Variant"}
                                                selectValue="id"
                                                selectName="name"
                                                placeholder={
                                                  formik.values
                                                    .secondaryDescription[index]
                                                    .variant
                                                }
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikInput
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .secondaryDescription &&
                                                  formik.touched
                                                    .secondaryDescription &&
                                                  formik.errors
                                                    .secondaryDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .secondaryDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .secondaryDescription[index]
                                                    .fontWeight &&
                                                  formik.touched
                                                    .secondaryDescription[index]
                                                    .fontWeight
                                                }
                                                name={`secondaryDescription[${index}].fontWeight`}
                                                type="number"
                                                label={"Font Weight"}
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <CommonCheckBox
                                                name={`secondaryDescription[${index}].isBr`}
                                                label={"Line Break"}
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              alignItems={"center"}
                                            >
                                              <FormikInput
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .secondaryDescription &&
                                                  formik.touched
                                                    .secondaryDescription &&
                                                  formik.errors
                                                    .secondaryDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .secondaryDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .secondaryDescription[index]
                                                    .description &&
                                                  formik.touched
                                                    .secondaryDescription[index]
                                                    .description
                                                }
                                                multiline
                                                rows={4}
                                                name={`secondaryDescription[${index}].description`}
                                                type="text"
                                                label={"Description"}
                                              />
                                            </Grid>
                                          </Grid>
                                        )
                                      )}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={
                          formik.errors.caseStudiesDescription &&
                          formik.touched.caseStudiesDescription
                        }
                        name={"caseStudiesDescription"}
                        type="text"
                        label={"Case Studies Description"}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                        style={{ backgroundColor: "#F7F9FF" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            The Situation
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FieldArray name={"situationDescription"}>
                              {(fieldArrayProps) => {
                                const { push, remove, form } = fieldArrayProps;
                                const { values } = form;
                                const { situationDescription } = values;

                                return (
                                  <div style={{ width: "100%" }}>
                                    {situationDescription &&
                                      situationDescription.map(
                                        (situationDescription, index) => (
                                          <Grid
                                            container
                                            spacing={1}
                                            key={index}
                                          >
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              style={{ marginTop: "2rem" }}
                                              justifyContent={"flex-end"}
                                            >
                                              <AddCircleOutlineIcon
                                                style={{
                                                  color: "#33CC70",
                                                  fontSize: "2rem",
                                                }}
                                                onClick={() => {
                                                  return (
                                                    push({
                                                      variant: "",
                                                      description: "",
                                                      fontWeight: 400,
                                                      isBr: false,
                                                    }),
                                                    situationDescriptionArr.push(
                                                      1
                                                    ),
                                                    setSituationDescriptionArr(
                                                      situationDescriptionArr
                                                    )
                                                  );
                                                }}
                                              />
                                              {situationDescriptionArr.length !==
                                                1 && (
                                                <DeleteOutlineIcon
                                                  style={{
                                                    color: "red",
                                                    fontSize: "2rem",
                                                  }}
                                                  onClick={() => {
                                                    return situationDescriptionArr.length ===
                                                      1
                                                      ? null
                                                      : (situationDescriptionArr.pop(
                                                          index
                                                        ),
                                                        setSituationDescriptionArr(
                                                          situationDescriptionArr
                                                        ),
                                                        remove(index));
                                                  }}
                                                />
                                              )}
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikSelect
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .situationDescription &&
                                                  formik.touched
                                                    .situationDescription &&
                                                  formik.errors
                                                    .situationDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .situationDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .situationDescription[index]
                                                    .variant &&
                                                  formik.touched
                                                    .situationDescription[index]
                                                    .variant
                                                }
                                                name={`situationDescription[${index}].variant`}
                                                options={variantType}
                                                label={"Font Variant"}
                                                selectValue="id"
                                                selectName="name"
                                                placeholder={
                                                  formik.values
                                                    .situationDescription[index]
                                                    .variant
                                                }
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikInput
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .situationDescription &&
                                                  formik.touched
                                                    .situationDescription &&
                                                  formik.errors
                                                    .situationDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .situationDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .situationDescription[index]
                                                    .fontWeight &&
                                                  formik.touched
                                                    .situationDescription[index]
                                                    .fontWeight
                                                }
                                                name={`situationDescription[${index}].fontWeight`}
                                                type="number"
                                                label={"Font Weight"}
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <CommonCheckBox
                                                name={`situationDescription[${index}].isBr`}
                                                label={"Line Break"}
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              alignItems={"center"}
                                            >
                                              <FormikInput
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .situationDescription &&
                                                  formik.touched
                                                    .situationDescription &&
                                                  formik.errors
                                                    .situationDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .situationDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .situationDescription[index]
                                                    .description &&
                                                  formik.touched
                                                    .situationDescription[index]
                                                    .description
                                                }
                                                multiline
                                                rows={4}
                                                name={`situationDescription[${index}].description`}
                                                type="text"
                                                label={"Description"}
                                              />
                                            </Grid>
                                          </Grid>
                                        )
                                      )}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                        style={{ backgroundColor: "#F7F9FF" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            The Solution
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FieldArray name={"solutionDescription"}>
                              {(fieldArrayProps) => {
                                const { push, remove, form } = fieldArrayProps;
                                const { values } = form;
                                const { solutionDescription } = values;

                                return (
                                  <div style={{ width: "100%" }}>
                                    {solutionDescription &&
                                      solutionDescription.map(
                                        (solutionDescription, index) => (
                                          <Grid
                                            container
                                            spacing={1}
                                            key={index}
                                          >
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              style={{ marginTop: "2rem" }}
                                              justifyContent={"flex-end"}
                                            >
                                              <AddCircleOutlineIcon
                                                style={{
                                                  color: "#33CC70",
                                                  fontSize: "2rem",
                                                }}
                                                onClick={() => {
                                                  return (
                                                    push({
                                                      variant: "",
                                                      description: "",
                                                      fontWeight: 400,
                                                      isBr: false,
                                                    }),
                                                    solutionDescriptionArr.push(
                                                      1
                                                    ),
                                                    setSolutionDescriptionArr(
                                                      solutionDescriptionArr
                                                    )
                                                  );
                                                }}
                                              />
                                              {solutionDescriptionArr.length !==
                                                1 && (
                                                <DeleteOutlineIcon
                                                  style={{
                                                    color: "red",
                                                    fontSize: "2rem",
                                                  }}
                                                  onClick={() => {
                                                    return solutionDescriptionArr.length ===
                                                      1
                                                      ? null
                                                      : (solutionDescriptionArr.pop(
                                                          index
                                                        ),
                                                        setSolutionDescriptionArr(
                                                          solutionDescriptionArr
                                                        ),
                                                        remove(index));
                                                  }}
                                                />
                                              )}
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikSelect
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .solutionDescription &&
                                                  formik.touched
                                                    .solutionDescription &&
                                                  formik.errors
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .solutionDescription[index]
                                                    .variant &&
                                                  formik.touched
                                                    .solutionDescription[index]
                                                    .variant
                                                }
                                                name={`solutionDescription[${index}].variant`}
                                                options={variantType}
                                                label={"Font Variant"}
                                                selectValue="id"
                                                selectName="name"
                                                placeholder={
                                                  formik.values
                                                    .solutionDescription[index]
                                                    .variant
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikSelect
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .solutionDescription &&
                                                  formik.touched
                                                    .solutionDescription &&
                                                  formik.errors
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .solutionDescription[index]
                                                    .color &&
                                                  formik.touched
                                                    .solutionDescription[index]
                                                    .color
                                                }
                                                name={`solutionDescription[${index}].color`}
                                                options={fontColorType}
                                                label={"Font color"}
                                                selectValue="id"
                                                selectName="name"
                                                placeholder={
                                                  formik.values
                                                    .solutionDescription[index]
                                                    .color
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <FormikInput
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .solutionDescription &&
                                                  formik.touched
                                                    .solutionDescription &&
                                                  formik.errors
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .solutionDescription[index]
                                                    .fontWeight &&
                                                  formik.touched
                                                    .solutionDescription[index]
                                                    .fontWeight
                                                }
                                                name={`solutionDescription[${index}].fontWeight`}
                                                type="number"
                                                label={"Font Weight"}
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={3}
                                              lg={3}
                                              alignItems={"center"}
                                            >
                                              <CommonCheckBox
                                                name={`solutionDescription[${index}].isBr`}
                                                label={"Line Break"}
                                              />
                                            </Grid>

                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              alignItems={"center"}
                                            >
                                              <FormikInput
                                                error={
                                                  Object.keys(formik.errors)
                                                    .length !== 0 &&
                                                  Object.keys(formik.touched)
                                                    .length !== 0 &&
                                                  formik.errors
                                                    .solutionDescription &&
                                                  formik.touched
                                                    .solutionDescription &&
                                                  formik.errors
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.touched
                                                    .solutionDescription[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .solutionDescription[index]
                                                    .description &&
                                                  formik.touched
                                                    .solutionDescription[index]
                                                    .description
                                                }
                                                multiline
                                                rows={4}
                                                name={`solutionDescription[${index}].description`}
                                                type="text"
                                                label={"Description"}
                                              />
                                            </Grid>
                                          </Grid>
                                        )
                                      )}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={
                          formik.errors.cardDescription &&
                          formik.touched.cardDescription
                        }
                        name={"cardDescription"}
                        type="text"
                        label={"Card Description"}
                        required
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    size={"large"}
                    variant={"outlined"}
                    style={{ color: "#000046", marginRight: 10 }}
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={"large"}
                    variant={"contained"}
                    type={"submit"}
                    style={{ backgroundColor: "#000046", color: "#fff" }}
                  >
                    {loading ? (
                      <CircularProgress style={{ color: "#fff" }} size={25} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

export default ServicesModal;
