import {
    HANDLE_MODAL
} from '../types/sharedTypes';

/**
 |--------------------------------------------------
 | handle modal
 |--------------------------------------------------
 */
export const modalReducer = (
    state = {
        H0ME_1_SECTION_MODAL: {
            modalName: "H0ME_1_SECTION_MODAL",
            modalStatus: false
        },
        H0ME_2_SECTION_MODAL: {
            modalName: "H0ME_2_SECTION_MODAL",
            modalStatus: false
        },
        H0ME_3_SECTION_MODAL: {
            modalName: "H0ME_3_SECTION_MODAL",
            modalStatus: false
        },
        H0ME_4_SECTION_MODAL: {
            modalName: "H0ME_4_SECTION_MODAL",
            modalStatus: false
        },
        H0ME_5_SECTION_MODAL: {
            modalName: "H0ME_5_SECTION_MODAL",
            modalStatus: false
        },
        H0ME_6_SECTION_MODAL: {
            modalName: "H0ME_6_SECTION_MODAL",
            modalStatus: false
        },
        H0ME_FOOTER_SECTION_MODAL: {
            modalName: "H0ME_FOOTER_SECTION_MODAL",
            modalStatus: false
        },
        QUESTION_MODAL: {
            modalName: "QUESTION_MODAL",
            modalStatus: false
        },
        BLOGS_MODAL: {
            modalName: "BLOGS_MODAL",
            modalStatus: false
        },
        BLOG_NEW_MODAL: {
            modalName: "BLOG_NEW_MODAL",
            modalStatus: false
        },
        ADVISORS_AND_PLANNERS_MODAL: {
            modalName: "ADVISORS_AND_PLANNERS_MODAL",
            modalStatus: false
        },
        DM_TEAM_MODAL: {
            modalName: "DM_TEAM_MODAL",
            modalStatus: false
        },
        CASH_FLOW_MODAL: {
            modalName: "CASH_FLOW_MODAL",
            modalStatus: false
        },
        DEBT_ADVICE_MODAL: {
            modalName: "DEBT_ADVICE_MODAL",
            modalStatus: false
        },
        ESTATE_PLANNING_MODAL: {
            modalName: "ESTATE_PLANNING_MODAL",
            modalStatus: false
        },
        INDEPENDENT_FINANCIAL_MODAL: {
            modalName: "INDEPENDENT_FINANCIAL_MODAL",
            modalStatus: false
        },
        LIFESTYLE_AND_FAMILY_MODAL: {
            modalName: "LIFESTYLE_AND_FAMILY_MODAL",
            modalStatus: false
        },
        RETIREMENT_PLANNING_MODAL: {
            modalName: "RETIREMENT_PLANNING_MODAL",
            modalStatus: false
        },
        SMSF_ADVICE_AND_SETUP_MODAL: {
            modalName: "SMSF_ADVICE_AND_SETUP_MODAL",
            modalStatus: false
        },
        OUR_PROCESS_MODAL: {
            modalName: "OUR_PROCESS_MODAL",
            modalStatus: false
        },
        CERTIFIED_FINANCIAL_PLANNER_MODAL: {
            modalName: "CERTIFIED_FINANCIAL_PLANNER_MODAL",
            modalStatus: false
        },
        FULL_SCREEN_MODAL: {
            modalName: "FULL_SCREEN_MODAL",
            modalStatus: false
        },
        CLIENT_FEEDBACK_MODAL: {
            modalName: "CLIENT_FEEDBACK_MODAL",
            modalStatus: false
        },
        CREATE_CLIENT_FEEDBACK_MODAL: {
            modalName: "CREATE_CLIENT_FEEDBACK_MODAL",
            modalStatus: false
        },
        VIEW_CLIENT_FEEDBACK_MODAL: {
            modalName: "VIEW_CLIENT_FEEDBACK_MODAL",
            modalStatus: false
        },
        VIEW_INQUIRIES_MODAL: {
            modalName: "VIEW_INQUIRIES_MODAL",
            modalStatus: false
        },
        CONFIRMATION_MODAL: {
            modalName: "CONFIRMATION_MODAL",
            modalStatus: false
        },
        UPLOAD_PDF_MODAL: {
            modalName: "UPLOAD_PDF_MODAL",
            modalStatus: false
        },
    },
    action
) => {
    switch (action.type) {
        // modal type -H0ME_1_SECTION_MODAL
        case HANDLE_MODAL:
            if (action.payload.modalName === "H0ME_1_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_1_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "H0ME_2_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_2_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "H0ME_3_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_3_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "H0ME_4_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_4_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "H0ME_5_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_5_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "H0ME_6_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_6_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "H0ME_FOOTER_SECTION_MODAL") {
                state = {
                    ...state,
                    H0ME_FOOTER_SECTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "QUESTION_MODAL") {
                state = {
                    ...state,
                    QUESTION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "BLOGS_MODAL") {
                state = {
                    ...state,
                    BLOGS_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "BLOG_NEW_MODAL") {
                state = {
                    ...state,
                    BLOG_NEW_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "ADVISORS_AND_PLANNERS_MODAL") {
                state = {
                    ...state,
                    ADVISORS_AND_PLANNERS_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "DM_TEAM_MODAL") {
                state = {
                    ...state,
                    DM_TEAM_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "CASH_FLOW_MODAL") {
                state = {
                    ...state,
                    CASH_FLOW_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "DEBT_ADVICE_MODAL") {
                state = {
                    ...state,
                    DEBT_ADVICE_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "ESTATE_PLANNING_MODAL") {
                state = {
                    ...state,
                    ESTATE_PLANNING_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "INDEPENDENT_FINANCIAL_MODAL") {
                state = {
                    ...state,
                    INDEPENDENT_FINANCIAL_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "LIFESTYLE_AND_FAMILY_MODAL") {
                state = {
                    ...state,
                    LIFESTYLE_AND_FAMILY_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "RETIREMENT_PLANNING_MODAL") {
                state = {
                    ...state,
                    RETIREMENT_PLANNING_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "SMSF_ADVICE_AND_SETUP_MODAL") {
                state = {
                    ...state,
                    SMSF_ADVICE_AND_SETUP_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "OUR_PROCESS_MODAL") {
                state = {
                    ...state,
                    OUR_PROCESS_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "CERTIFIED_FINANCIAL_PLANNER_MODAL") {
                state = {
                    ...state,
                    CERTIFIED_FINANCIAL_PLANNER_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "FULL_SCREEN_MODAL") {
                state = {
                    ...state,
                    FULL_SCREEN_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "CLIENT_FEEDBACK_MODAL") {
                state = {
                    ...state,
                    CLIENT_FEEDBACK_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "CREATE_CLIENT_FEEDBACK_MODAL") {
                state = {
                    ...state,
                    CREATE_CLIENT_FEEDBACK_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "VIEW_CLIENT_FEEDBACK_MODAL") {
                state = {
                    ...state,
                    VIEW_CLIENT_FEEDBACK_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "VIEW_INQUIRIES_MODAL") {
                state = {
                    ...state,
                    VIEW_INQUIRIES_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "CONFIRMATION_MODAL") {
                state = {
                    ...state,
                    CONFIRMATION_MODAL: action.payload
                }
            }
            if (action.payload.modalName === "UPLOAD_PDF_MODAL") {
                state = {
                    ...state,
                    UPLOAD_PDF_MODAL: action.payload
                }
            }
            return state

        default:
            return state;
    }
};