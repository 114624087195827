import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Stories = () => {
  const theme = useTheme();

  /**
|--------------------------------------------------
| redux integration
|--------------------------------------------------
*/
  const dmTeamPostReducer = useSelector((state) => state.dmTeamPostReducer);

  const { post } = dmTeamPostReducer;

  return (
    <Box>
      <Box marginBottom={4}>
        <Box component={Typography} fontWeight={700} variant={'h3'} align={'center'}>
          {post.length !== 0 && post[1].title}
        </Box>
      </Box>
      <Grid container spacing={4}>
        {post.length !== 0 &&
          post[1].description.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i} data-aos="flip-left">
              <Box
                component={'a'}
                href={''}
                display={'block'}
                width={'100%'}
                height={'100%'}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={'100%'}
                  height={'100%'}
                  borderRadius={3}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <CardMedia
                    image={item.media}
                    title={item.title}
                    sx={{
                      height: 240,
                    }}
                  />
                  <Box component={CardContent}>
                    <Typography align={'left'} variant={item.variant} color="textSecondary">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

Stories.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Stories;
