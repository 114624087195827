import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { Headline, Partners, Stories } from "./components";
import Container from "../../../common/Container";
import backgroundImg1 from "../../../assets/img/backgroundImg1.png";

const DMTeam = ({ themeMode = "light" }) => {
  const theme = useTheme();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box
        minHeight={{ xs: "auto", md: "calc(40vh - 64px)" }}
        height={"auto"}
        position={"relative"}
        sx={{
          backgroundImage: `url(${backgroundImg1})`,
          backgroundSize: "cover",
          backgroundPosition: "center right",
          zIndex: 10,
          "&:after": {
            position: "absolute",
            content: '" "',
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: theme.palette.common.black,
            opacity: 0.35,
          },
        }}
      >
        <Box
          position={{ xs: "relative", md: "absolute" }}
          top={{ xs: 0, md: "50%" }}
          width={"100%"}
          height={"50%"}
          sx={{
            transform: { md: "translateY(-50%)" },
          }}
          zIndex={2}
        >
          <Container>
            <Headline />
          </Container>
        </Box>
      </Box>
      <Container>
        <Partners />
      </Container>
      <Box bgcolor={theme.palette.alternate.main} position={"relative"}>
        <Container position="relative" zIndex={2}>
          <Stories themeMode={themeMode} />
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
          sx={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            height: "35%",
          }}
        >
          <polygon
            fill={theme.palette.background.paper}
            points="0,273 1921,273 1921,0 "
          />
        </Box>
      </Box>
    </Box>
  );
};

DMTeam.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default DMTeam;
