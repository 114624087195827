import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typed from "react-typed";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CallIcon from "@mui/icons-material/Call";
import { baseURL } from "../../../../utils/API_URLS";
import DMfinancialservices2 from "../../../../assets/img/DMfinancialservices2.png";

const Content = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const cashFlowPostReducer = useSelector((state) => state.cashFlowPostReducer);

  const { post } = cashFlowPostReducer;

  const data = post.filter((data) => data.title === "debtAdviceMortgageBroker");

  return (
    <Box>
      {isMd && (
        <Box
          style={{
            position: "absolute",
            zIndex: 0,
            top: 500,
            left: -100,
          }}
        >
          <svg
            width="241"
            height="572"
            viewBox="0 0 241 572"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="-45"
              cy="286"
              r="286"
              fill="url(#paint0_linear_400_170)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_400_170"
                x1="151.352"
                y1="86.5872"
                x2="-212.052"
                y2="458.737"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#11B735" />
                <stop offset="1" stop-color="#8AF7A2" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      )}
      {isMd && (
        <Box
          style={{
            position: "absolute",
            zIndex: 0,
            top: 500,
            right: 0,
          }}
        >
          <svg
            width="104"
            height="236"
            viewBox="0 0 104 236"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="118"
              cy="118"
              r="118"
              fill="url(#paint0_linear_400_171)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_400_171"
                x1="199.012"
                y1="35.7248"
                x2="49.0764"
                y2="189.269"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#11B735" />
                <stop offset="1" stop-color="#8AF7A2" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      )}

      <Grid container spacing={4}>
        <Grid item container alignItems={"center"} xs={12} md={12}>
          <Box data-aos={isMd ? "fade-right" : "fade-up"}>
            <Box marginBottom={2}>
              <Typography variant={"h4"} sx={{ fontWeight: 700 }} gutterBottom>
                {data.length !== 0 && data[0].title2}
              </Typography>
              <Typography variant={"h6"} component={"p"} color="textSecondary">
                <Box component={"span"} fontWeight={400}>
                  {data.length !== 0 && data[0].mainDescription}
                </Box>
              </Typography>
              <br />

              <Box
                minHeight={{ xs: "auto", md: "calc(50vh - 64px)" }}
                position={"relative"}
                sx={{
                  backgroundImage: `url(${DMfinancialservices2})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center right",
                  //   backgroundAttachment: "fixed",
                  borderRadius: 10,
                }}
              ></Box>
              <br />
              {data.length !== 0 &&
                data[0].secondaryDescription.map((data, index) => (
                  <Typography
                    variant={data.variant}
                    component={"p"}
                    color="textSecondary"
                    key={index}
                  >
                    <Box component={"span"} fontWeight={data.fontWeight}>
                      {data.description}
                    </Box>
                    {data.isBr && <br />}
                  </Typography>
                ))}
            </Box>
          </Box>
          <Box
            data-aos={isMd ? "fade-right" : "fade-up"}
            style={{ width: "100%" }}
          >
            <Typography variant={"h4"} sx={{ fontWeight: 700 }} gutterBottom>
              Case Studies
            </Typography>
            <Typography variant={"h6"} sx={{ fontWeight: 400 }} gutterBottom>
              {data.length !== 0 && data[0].caseStudiesDescription}
            </Typography>
          </Box>

          <Box data-aos={isMd ? "fade-right" : "fade-up"}>
            <Typography
              variant={"h5"}
              sx={{ fontWeight: 700 }}
              gutterBottom
              color="primary"
            >
              The situation
            </Typography>

            {data.length !== 0 &&
              data[0].situationDescription.map((data, index) => (
                <Typography
                  variant={data.variant}
                  component={"p"}
                  color="textSecondary"
                  key={index}
                >
                  <Box component={"span"} fontWeight={data.fontWeight}>
                    {data.description}
                  </Box>
                  {data.isBr && <br />}
                </Typography>
              ))}
          </Box>

          <Box data-aos={isMd ? "fade-right" : "fade-up"} marginTop={5}>
            <Typography
              variant={"h5"}
              sx={{ fontWeight: 700 }}
              gutterBottom
              color="primary"
            >
              The Solution
            </Typography>

            <Box
              boxShadow={0}
              style={{
                float: "left",
                //   minWidth: matches ? "100%" : 300,
                marginRight: 30,
                borderRadius: "50%",
                padding: 20,
                width: 100,
                height: 100,
              }}
              bgcolor="#11B735"
            >
              <svg
                width="58"
                height="58"
                viewBox="0 0 58 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.3523 41.0833H34.6453M29 7.25V9.66667M44.3797 13.6203L42.6711 15.3289M50.75 29H48.3333M9.66667 29H7.25M15.3289 15.3289L13.6203 13.6203M20.4547 37.5453C18.7651 35.8553 17.6146 33.7023 17.1486 31.3584C16.6826 29.0146 16.9221 26.5852 17.8368 24.3774C18.7514 22.1697 20.3002 20.2827 22.2872 18.9551C24.2742 17.6275 26.6103 16.9189 29 16.9189C31.3897 16.9189 33.7258 17.6275 35.7128 18.9551C37.6998 20.2827 39.2486 22.1697 40.1632 24.3774C41.0779 26.5852 41.3174 29.0146 40.8514 31.3584C40.3854 33.7023 39.2349 35.8553 37.5453 37.5453L36.221 38.8673C35.4639 39.6245 34.8633 40.5235 34.4537 41.5129C34.044 42.5022 33.8332 43.5626 33.8333 44.6334V45.9167C33.8333 47.1985 33.3241 48.4279 32.4177 49.3343C31.5113 50.2408 30.2819 50.75 29 50.75C27.7181 50.75 26.4887 50.2408 25.5823 49.3343C24.6759 48.4279 24.1667 47.1985 24.1667 45.9167V44.6334C24.1667 42.4705 23.3063 40.3946 21.779 38.8673L20.4547 37.5453Z"
                  stroke="white"
                  stroke-width="4.83333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>

            {data.length !== 0 &&
              data[0].solutionDescription.map((data, index) => (
                <Typography
                  variant={data.variant}
                  component={"p"}
                  color={data.color}
                  key={index}
                >
                  <Box component={"span"} fontWeight={data.fontWeight}>
                    {data.description}
                  </Box>
                  {data.isBr && <br />}
                </Typography>
              ))}
          </Box>
          <Box
            data-aos={isMd ? "fade-right" : "fade-up"}
            minHeight={{ xs: "auto", md: "calc(40vh - 64px)" }}
            height={"auto"}
            width={"100%"}
            style={{
              background: "linear-gradient(to right, #007991, #38ef7d)",
              padding: 15,
              borderRadius: 6,
            }}
          >
            <Grid container>
              <Grid item xs={12} md={7}>
                <Box marginBottom={2}>
                  <Box>
                    <Typography
                      variant={"h5"}
                      component={"p"}
                      sx={{ color: "#fff" }}
                      marginBottom={2}
                    >
                      {data.length !== 0 && data[0].cardDescription}
                    </Typography>

                    <Typography
                      component={"a"}
                      href={`${baseURL}/file/DebtAdvice.pdf`}
                      target="_blank"
                      sx={{ color: "#fff", textDecoration: "none" }}
                      variant={"subtitle1"}
                    >
                      More details -{" "}
                      <Typed
                        strings={["PDF View"]}
                        typeSpeed={70}
                        loop={true}
                      />
                    </Typography>
                    <Typography
                      variant={"h6"}
                      sx={{ marginTop: 5, color: "#fff" }}
                    >
                      <Box component={"span"} fontWeight={400}></Box>
                    </Typography>

                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      fullWidth={isMd ? false : true}
                      component={Link}
                      to="/contact-Us"
                      startIcon={<CallIcon />}
                    >
                      Call Damien Burns
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12} md={5}>
                <Box
                  height={"100%"}
                  width={"100%"}
                  display={isMd ? "flex" : "none"}
                  flexDirection={"column"}
                  sx={{
                    backgroundColor: "transparent",
                    backgroundImage:
                      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='21.12'%3E%3Cpath d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='7.040000000000001' transform='' cx='500' cy='100' r='40'/%3E%3Cpath transform='' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='6.4'%3E%3Cpath transform='' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='14.080000000000002' transform='' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
                    backgroundSize: "contain",
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      "https://assets.maccarianagency.com/backgrounds/img1.jpg"
                    }
                    alt="..."
                    width={80}
                    height={80}
                    marginLeft={"calc(60% - 100px)"}
                    zIndex={3}
                    borderRadius={"100%"}
                    boxShadow={4}
                    data-aos={"fade-up"}
                    sx={{
                      objectFit: "cover",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.5)"
                          : "none",
                    }}
                  />
                  <Box
                    component={"img"}
                    width={100}
                    height={100}
                    src={
                      "https://assets.maccarianagency.com/backgrounds/img2.jpg"
                    }
                    alt="..."
                    marginLeft={"calc(60% - 200px)"}
                    marginTop={"-8%"}
                    zIndex={2}
                    borderRadius={"100%"}
                    boxShadow={4}
                    data-aos={"fade-up"}
                    sx={{
                      objectFit: "cover",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.5)"
                          : "none",
                    }}
                  />
                  <Box
                    component={"img"}
                    width={150}
                    height={150}
                    src={
                      "https://assets.maccarianagency.com/backgrounds/img4.jpg"
                    }
                    alt="..."
                    marginTop={"-20%"}
                    marginLeft={"calc(100% - 150px)"}
                    zIndex={1}
                    borderRadius={"100%"}
                    boxShadow={4}
                    data-aos={"fade-up"}
                    sx={{
                      objectFit: "cover",
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.5)"
                          : "none",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={5}>
            <Typography variant={"body2"} color="textSecondary">
              Please be aware that this is a case study and that individual
              circumstances can be different and thereby achieve differing
              outcomes. Please take advice from a professional financial adviser
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Content;
