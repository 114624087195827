import { combineReducers } from 'redux';
import { notificationReducer } from "./notificationReducer"
import { auth } from "./auth"
import { modalReducer } from "./modalReducer"
import {
    allContentReducer,
    updateHomeContentReducer,
    allFrequentQuestionReducer,
    updateFrequentlyQuestionReducer,
    allAboutUsReducer,
    allBlogPostReducer,
    updateBlogPostReducer,
    createBlogPostReducer,
    dmTeamPostReducer,
    updateDmTeamPostReducer,
    cashFlowPostReducer,
    updateCashFlowPostReducer,
    ourProcessPostReducer,
    updateOurProcessPostReducer,
    certifiedFinancialPlannerPostReducer,
    updateCertifiedFinancialPlannerReducer,
    clientFeedbackPostReducer,
    updateClientFeedbackReducer,
    createClientFeedbackReducer,
    InquiryEmailReducer,
    updateInquiryEmailReducer,
    createInquiryEmailReducer,
    getEmailReducer,
    updateGetPdfEmailReducer,
    createGetPdfEmailReducer,
    getSubscribeEmailReducer,
    updateSubscribeReducer,
    createSubscribeEmailReducer,
    deleteSubscribeEmailReducer,
    deleteGetPdfEmailReducer,
    deleteInquiryEmailReducer
} from "./contentReducers"

const rootReducer = combineReducers({
    notificationReducer,
    auth,
    modalReducer,
    allContentReducer,
    updateHomeContentReducer,
    allFrequentQuestionReducer,
    updateFrequentlyQuestionReducer,
    allAboutUsReducer,
    allBlogPostReducer,
    updateBlogPostReducer,
    createBlogPostReducer,
    dmTeamPostReducer,
    updateDmTeamPostReducer,
    cashFlowPostReducer,
    updateCashFlowPostReducer,
    ourProcessPostReducer,
    updateOurProcessPostReducer,
    certifiedFinancialPlannerPostReducer,
    updateCertifiedFinancialPlannerReducer,
    clientFeedbackPostReducer,
    updateClientFeedbackReducer,
    createClientFeedbackReducer,
    InquiryEmailReducer,
    updateInquiryEmailReducer,
    createInquiryEmailReducer,
    getEmailReducer,
    updateGetPdfEmailReducer,
    createGetPdfEmailReducer,
    getSubscribeEmailReducer,
    updateSubscribeReducer,
    createSubscribeEmailReducer,
    deleteSubscribeEmailReducer,
    deleteGetPdfEmailReducer,
    deleteInquiryEmailReducer
});

export default rootReducer;
