import React from "react";

const ContactUs = () => {
  return (
    <div>
      <h1>developing</h1>
    </div>
  );
};

export default ContactUs;
