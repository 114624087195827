import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '@mui/material/Typography';

import celebratingIncome from '../../../../assets/img/celebratingIncome.PNG';

import { createGetPdfEmailAction } from '../../../../redux/actions/contentAction';

const Contact = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const allContentReducer = useSelector((state) => state.allContentReducer);

  const H0ME_3_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter((data) => data.title === 'H0ME_3_SECTION');

  /**
 |--------------------------------------------------
 | initial values
 |--------------------------------------------------
 */
  const initialValues = {
    fromMail: '',
  };

  const validationSchema = yup.object({
    fromMail: yup
      .string('Enter your email')
      .trim()
      .email('Please enter a valid email address')
      .required('Email is required.'),
  });

  /**
 |--------------------------------------------------
 | check authentication
 |--------------------------------------------------
 */

  const onSubmit = (values, formikProps) => {
    values.username = 'adminDamien';
    dispatch(createGetPdfEmailAction(values));
    formikProps.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Grid container>
      <Grid container item alignItems={'center'} xs={12} sm={12} md={8} lg={8}>
        <Box>
          <Typography
            variant="h3"
            align={'center'}
            data-aos={'fade-up'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            {H0ME_3_SECTION && H0ME_3_SECTION[0].desc_1}
          </Typography>
          <Typography
            variant="h6"
            align={'center'}
            color={'textSecondary'}
            data-aos={'fade-up'}
            marginBottom={2}
          >
            {H0ME_3_SECTION && H0ME_3_SECTION[0].desc_2}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent={'center'}
          margin={'0 auto'}
          data-aos={'fade-up'}
        >
          <Box
            sx={{
              '& .MuiInputBase-input.MuiOutlinedInput-input': {
                bgcolor: 'background.paper',
              },
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
              >
                <Box flex={'1 2'}>
                  <TextField
                    label="Email *"
                    variant="outlined"
                    name={'fromMail'}
                    margin="dense"
                    fullWidth
                    placeholder="Your email address"
                    value={formik.values.fromMail}
                    onChange={formik.handleChange}
                    error={formik.touched.fromMail && Boolean(formik.errors.fromMail)}
                    helperText={formik.touched.fromMail && formik.errors.fromMail}
                    style={{ backgroundColor: '#fff' }}
                  />
                </Box>

                <Box marginTop={{ xs: 2, sm: 0 }} marginLeft={{ sm: 2 }}>
                  <Button fullWidth size={'large'} variant={'contained'} type={'submit'}>
                    {H0ME_3_SECTION && H0ME_3_SECTION[0].desc_3}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
      {isMd && (
        <Grid container item xs={12} sm={12} md={4} lg={4}>
          <img
            src={celebratingIncome}
            style={{ width: '100%', height: 270, borderRadius: '1rem' }}
            alt="celebrating-Income"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Contact;
