import React from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Map = ({ themeMode = "light" }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box>
        <Box>
          <Typography variant="h6" color={"textSecondary"}>
            We'd love to talk about how we can help you.
          </Typography>
        </Box>
      </Box>
      <Box marginY={4}>
        <Box
          component={"iframe"}
          borderRadius={2}
          minHeight={400}
          width="100%"
          height="100%"
          frameBorder="0"
          title="map"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.10466693811!2d145.36673931579136!3d-38.04464745506381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad61bd1d056cd3d%3A0xb572102cc2a18755!2sAustralia%20Post%20-%20Beaconsfield%20LPO!5e0!3m2!1sen!2slk!4v1637430513713!5m2!1sen!2slk"
          sx={{
            filter:
              themeMode === "dark" ? "grayscale(0.5) opacity(0.7)" : "none",
          }}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={"body1"}
            gutterBottom
            sx={{ fontWeight: "medium" }}
          >
            Call us:
          </Typography>
          <Typography
            variant={"subtitle1"}
            component="a"
            target="blank"
            href={`tel:${"0413 698 770"}`}
            style={{
              color: theme.palette.text.primary,
              outline: "none",
              textDecoration: "none",
            }}
          >
            {" "}
            0413 698 770
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={"body1"}
            gutterBottom
            sx={{ fontWeight: "medium" }}
          >
            Email us:
          </Typography>
          <Typography
            variant={"subtitle1"}
            component="a"
            target="blank"
            href={`mailto:${"info@dmfinancialservices.com.au"}`}
            style={{
              color: theme.palette.text.primary,
              outline: "none",
              textDecoration: "none",
            }}
          >
            info@dmfinancialservices.com.au
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={"body1"}
            gutterBottom
            sx={{ fontWeight: "medium" }}
          >
            Skype
          </Typography>
          <Typography
            variant={"subtitle1"}
            component="a"
            target="blank"
            href={`skype:${"Damien.DMFS"}`}
            style={{
              color: theme.palette.text.primary,
              outline: "none",
              textDecoration: "none",
            }}
          >
            Damien.DMFS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={"body1"}
            gutterBottom
            sx={{ fontWeight: "medium" }}
          >
            Address:
          </Typography>
          <Typography variant={"subtitle1"}>
            PO BOX 703 Beaconsfield VIC 3807
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

Map.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Map;
