import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Typed from 'react-typed';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import DMFinancialServicesLogo from '../../../../assets/img/img-logo-dmfs-small.png';

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const isLg = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const allContentReducer = useSelector((state) => state.allContentReducer);

  const H0ME_1_SECTION =
    allContentReducer.allContent &&
    allContentReducer.allContent.filter((data) => data.title === 'H0ME_1_SECTION');

  return (
    <Grid container>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <img src={DMFinancialServicesLogo} alt="D+M-financial-services" />
          <Box marginBottom={2}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 550,
                color: '#fff',
              }}
            >
              {H0ME_1_SECTION && H0ME_1_SECTION[0].desc_1}
              <br />
              <Typography
                component={'span'}
                variant={'inherit'}
                sx={{
                  fontWeight: 500,
                  color: '#08ff83',
                }}
              >
                <Typed
                  strings={H0ME_1_SECTION ? [H0ME_1_SECTION[0].desc_2] : ['']}
                  typeSpeed={70}
                  loop={true}
                />
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              sx={{
                fontWeight: 400,
                color: '#fff',
                fontSize: '1.3rem',
              }}
            >
              {H0ME_1_SECTION && H0ME_1_SECTION[0].desc_3}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
            >
              {H0ME_1_SECTION && H0ME_1_SECTION[0].desc_4}
            </Button>
          </Box>
        </Box>
        <Box
          className="arrow bounce"
          display={'flex'}
          justifyContent={isLg ? 'flex-end' : 'center'}
          width={'100%'}
        >
          <ArrowDownwardRoundedIcon
            onClick={() => window.scrollTo(0, 800)}
            className="fa fa-arrow-down fa-2x"
            style={{
              color: '#7cf3a1',
              fontSize: '3.5rem',
              textAlign: 'right',
              cursor: 'pointer',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
