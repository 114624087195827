//export const baseURL = "http://localhost:5888"
// export const baseURL = "https://dmfinance.herokuapp.com"
export const baseURL = 'https://dmfinancebackend.azurewebsites.net';
export const API_URL = {
  login: 'auth/login',
  getAllContent: 'posts',
  updateHomeContent: 'posts/{id}',

  getAllFrequentlyQuestions: 'frequentlyQuestions',
  updateFrequentlyQuestions: 'frequentlyQuestions/{id}',

  getAllAboutUs: 'aboutUs',
  updateAboutUs: 'aboutUs/{id}',

  getAllBlogPost: 'blogPost',
  updateBlogPost: 'blogPost/{id}',
  createBlogPost: 'blogPost',

  getDmTeamPost: 'dmTeamRoute',
  updateDmTeamPost: 'dmTeamRoute/{id}',

  getCashFlowPost: 'serviceCashFlowRoute',
  updateGetCashFlowPost: 'serviceCashFlowRoute/{id}',

  getOurProcessPost: 'ourProcessRoute',
  updateOurProcessPost: 'ourProcessRoute/{id}',

  getCertifiedFinancialPlannerPost: 'certifiedFinancialPlannerRoute',
  updateCertifiedFinancialPlannerPost: 'certifiedFinancialPlannerRoute/{id}',

  getClientFeedbackPost: 'clientFeedbackRoute',
  createClientFeedbackPost: 'clientFeedbackRoute',
  updateClientFeedbackPost: 'clientFeedbackRoute/{id}',

  inquiryMail: 'mailerRoute/inquiry',

  getInquiryMail: 'inquiriesEmailRoute',
  createInquiryMail: 'inquiriesEmailRoute',
  updateInquiryMail: 'inquiriesEmailRoute/{id}',
  deleteInquiryMail: 'inquiriesEmailRoute/{id}',

  getRequestPdfMail: 'requestPdfRoute',
  createRequestPdfMail: 'requestPdfRoute',
  updateRequestPdfMail: 'requestPdfRoute/{id}',
  deleteRequestPdfMail: 'requestPdfRoute/{id}',

  getSubscribeMail: 'subscribeEmailRoute',
  createSubscribeMail: 'subscribeEmailRoute',
  updateSubscribeMail: 'subscribeEmailRoute/{id}',
  deleteSubscribeMail: 'subscribeEmailRoute/{id}',

  getPdfMail: 'mailerRoute/getPdf',
  subscribeMail: 'mailerRoute/subscribeMail',
};
