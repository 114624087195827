import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const Innovation = ({ width = 112, height = 115 }) => {
  const theme = useTheme();
  const colorPrimaryMain = theme.palette.primary.main;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 112 115"
    >
      <path
        fill="#E6E6E6"
        d="M56 114.065c30.828 0 55.82-2.039 55.82-4.555 0-2.516-24.992-4.555-55.82-4.555-30.829 0-55.82 2.039-55.82 4.555 0 2.516 24.991 4.555 55.82 4.555z"
        opacity="0.45"
      ></path>
      <path fill="#E6E6E6" d="M83.335 53.02H80.82v39.11h2.515V53.02z"></path>
      <path
        fill="#F4A28C"
        d="M51.125 54.785a6.11 6.11 0 01.5-2.085c.455-1 2.735-2 3.07-1.215.335.785-.135 1.86-.5 2.695a10.294 10.294 0 00-.445 2.705l-2.625-2.1z"
      ></path>
      <path
        fill="#68E1FD"
        d="M40.456 50s5.69 15.085 7.71 14.375c2.02-.71 2.68-9.955 2.68-9.955l3.335 1.21s.26 16.625-6.315 16.5-13.6-12.63-13.6-12.63l6.19-9.5z"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M40.456 50s5.69 15.085 7.71 14.375c2.02-.71 2.68-9.955 2.68-9.955l3.335 1.21s.26 16.625-6.315 16.5-13.6-12.63-13.6-12.63l6.19-9.5z"
        opacity="0.46"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M16.5 104.955s-.5 1.555-1.8 1.675c-1.3.12-1.625 1.5-.465 1.85 1.16.35 4.14-1.265 4.14-1.265l-.085-2.025-1.79-.235zM34.185 104.955s.5 1.555 1.8 1.675c1.3.12 1.62 1.5.465 1.85-1.155.35-4.14-1.265-4.14-1.265l.085-2.025 1.79-.235z"
      ></path>
      <path
        fill="#245B5B"
        d="M21.25 75.056l-5 30.18h2.5l6.29-16.385a27.74 27.74 0 0111.165-13.735l1.845-1.145-16.8 1.085z"
      ></path>
      <path
        fill="#245B5B"
        fillOpacity="0.9"
        d="M38.046 73.97l-2.595 31.266h-3.335s.78-23.525-5.235-28.53l11.165-2.735z"
      ></path>
      <path
        fill="#F4A28C"
        d="M27.104 35.214c.265 1.296.356 2.62.27 3.94a1.2 1.2 0 01-1.345 1.03 2.65 2.65 0 01-2.285-1.73l-.95-2a2.135 2.135 0 01.665-2.375c1.22-1.08 3.42-.3 3.645 1.135z"
      ></path>
      <path
        fill="#F4A28C"
        d="M22.97 36.836l-.31 7.79 4.305-.14-1.505-5.61-2.49-2.04z"
      ></path>
      <path
        fill="#5B2424"
        d="M26.53 35.38a9.319 9.319 0 01-2.2.096 2 2 0 01.26 2.16 1.62 1.62 0 01-1.866.87l-.32-3.04a2.424 2.424 0 01.965-2.29c.304-.227.623-.432.955-.615.83-.455 2.18-.025 2.895-.705a.57.57 0 01.945.265c.25.905.255 2.38-.935 3.03a2.138 2.138 0 01-.7.23z"
      ></path>
      <path
        fill="#F4A28C"
        d="M24.835 37.535s-.13-.91-.805-.755c-.675.154-.5 1.46.445 1.5l.36-.745zM27.33 36.7l.77.83a.38.38 0 01-.17.62l-.88.28.28-1.73z"
      ></path>
      <path
        fill="#CE8172"
        d="M25.84 40.09a2.866 2.866 0 01-1.5-.66s.23 1.405 1.95 2.62l-.45-1.96z"
        opacity="0.31"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M38.04 74.26l-7.24.535-9.545.705c-.205-.295-8.365-12.065-8.2-21.055.09-4.745 2.5-8.71 9.61-9.82l2.925-.095c.715-.019 1.428-.08 2.135-.185 2.26-.345 9.05-.76 12.735 5.655 4.465 7.78-2.42 24.26-2.42 24.26z"
      ></path>
      <path
        fill="#000"
        d="M30.965 74.875l-9.71.625c-.205-.295-8.365-12.065-8.2-21.055 5.5-2.55 14.38-3.41 14.38-3.41s1.81 1.865-.34 4.44c-2.65 3.17-3.585 7.5-2 11.32 1 2.46 2.5 6.365 5.715 8 .05.03.102.057.155.08z"
        opacity="0.08"
      ></path>
      <path
        fill="#68E1FD"
        d="M26.67 47.93a6.716 6.716 0 00-7.636-2.236C14 47.5 5.304 51.78 4.37 60.274 3.05 72.35 22.37 75 22.37 75l.62-3.075s-20-11.18-.62-16.805c-.02 0 7.725-2.56 4.3-7.19z"
      ></path>
      <path
        fill={colorPrimaryMain}
        d="M26.67 47.93a6.716 6.716 0 00-7.636-2.236C14 47.5 5.304 51.78 4.37 60.274 3.05 72.35 22.37 75 22.37 75l.62-3.075s-20-11.18-.62-16.805c-.02 0 7.725-2.56 4.3-7.19z"
        opacity="0.46"
      ></path>
      <path
        fill="#F4A28C"
        d="M23 72.37s3.305.064 2.725 2.13c-.58 2.064-3.375.5-3.375.5l.65-2.63z"
      ></path>
      <path
        fill="#FFD200"
        d="M68.63 54.465l-.236-1.34a.575.575 0 00-1.135.145l-4.575.445.146-1.315-6-1.325-.325.89c-.267-.326-.59-.6-.956-.81-.66-.385-1.5-.605-2.194-.145a2 2 0 00-.905 2.116c.125.367.304.714.53 1.03.201.315.445.6.725.85.41.35.89.608 1.41.755l-.5 1.334 7.5 1.675.194-1.73 5.246-2.204a.576.576 0 001.074-.37zm-14.095-.055a3.5 3.5 0 01-1-1.214.935.935 0 01-.18-.636c.065-.238.199-.452.385-.614a.645.645 0 01.524-.2c.245.031.48.115.69.244.472.256.851.653 1.085 1.136l-.62 1.69a2.7 2.7 0 01-.884-.406z"
      ></path>
      <path
        fill="#E6E6E6"
        d="M71.65 55.35c-.415-.373-.87-.7-1.356-.975a.215.215 0 00-.294.269.22.22 0 00.11.121c.45.262.873.57 1.26.92a.22.22 0 00.28-.335zM73.66 57.47a3.749 3.749 0 00-1-1.22.22.22 0 00-.28.335c.094.075.184.155.27.24l.06.055v.025l.12.14c.073.09.142.186.204.285.035.05.067.101.095.155v.1a.217.217 0 00.405-.155l.125.04zM74.81 59.685a4.952 4.952 0 00-.654-1.335.215.215 0 00-.35.25c.065.1.13.195.185.295a.718.718 0 01.05.08V59.08c0 .025 0 .055.04.08.103.213.19.434.26.66a.22.22 0 00.28.125.226.226 0 00.19-.26zM72.035 54c-.476-.231-.98-.4-1.5-.5a.22.22 0 00-.075.43c.48.085.946.234 1.385.445a.22.22 0 10.185-.395l.005.02zM75.216 56.345a6.156 6.156 0 00-1.3-1.17.22.22 0 00-.346.104.215.215 0 00.07.23 5.89 5.89 0 011.22 1.086.22.22 0 00.363-.011.213.213 0 00-.007-.24zM77.05 59.275a3.155 3.155 0 00-.83-1.455.22.22 0 10-.28.335l.036.035.06.065.12.145c.075.105.145.215.21.325v.025l.045.09.075.175c.05.122.09.247.12.375a.213.213 0 00.196.14.215.215 0 00.084-.015.22.22 0 00.165-.24zM75.295 61.93a1.835 1.835 0 00-.175-.715.21.21 0 00-.28-.12.225.225 0 00-.12.115.19.19 0 000 .165c.045.102.082.207.11.315.021.097.034.196.04.295a.196.196 0 00.075.14.206.206 0 00.16.05.214.214 0 00.19-.245zM71.075 57.04a4.678 4.678 0 00-1.425-1.54.225.225 0 00-.305.03.217.217 0 00-.024.239.215.215 0 00.054.065c.56.39 1.021.903 1.35 1.5a.21.21 0 00.3.05.215.215 0 00.05-.345zM72.63 59.765a4.273 4.273 0 00-.6-1.435.217.217 0 10-.356.25c.035.05.067.102.095.155l.045.07v.09l.04.075c0 .055.05.11.07.165.02.055.085.215.12.325.02.053.037.106.05.16 0 .03 0 .06.025.09a.182.182 0 010 .05.23.23 0 00.24.195.219.219 0 00.27-.19zM72.794 62.88v-1.12a.217.217 0 00-.43.045v1.12a.214.214 0 00.24.19.225.225 0 00.19-.235z"
      ></path>
      <path
        fill="#C9C9C9"
        d="M67.06 87.19h30.036l-2.25 21.339h-25.53l-2.255-21.34z"
      ></path>
      <path
        fill="#E6E6E6"
        d="M105.081 61.065a20.17 20.17 0 01-10.13 8.63c-7 2.645-11.865-.365-11.865-.365s1.665-5.45 8.69-8.09a20.154 20.154 0 0113.305-.175z"
      ></path>
      <path
        fill="#000"
        d="M105.081 61.065a20.17 20.17 0 01-10.13 8.63c-7 2.645-11.865-.365-11.865-.365l21.995-8.265z"
        opacity="0.05"
      ></path>
      <path
        fill="#E6E6E6"
        d="M59.07 69.36A20.165 20.165 0 0069.195 78c7.025 2.64 11.865-.37 11.865-.37s-1.66-5.45-8.685-8.09a20.156 20.156 0 00-13.305-.18z"
      ></path>
      <path
        fill="#000"
        d="M59.07 69.36A20.165 20.165 0 0069.195 78c7.025 2.64 11.865-.37 11.865-.37l-21.99-8.27z"
        opacity="0.05"
      ></path>
      <path
        fill="#C9C9C9"
        d="M82 56c4.418 0 8-1.343 8-3s-3.582-3-8-3-8 1.343-8 3 3.582 3 8 3z"
      ></path>
      <path
        fill="#FFD200"
        d="M82.15 41.236c11.128 0 20.15-9.022 20.15-20.15 0-11.129-9.022-20.15-20.15-20.15C71.021.936 62 9.957 62 21.086c0 11.128 9.022 20.15 20.15 20.15z"
      ></path>
      <path fill="#245B5B" d="M90 39.795H74v13.11h16v-13.11z"></path>
      <path
        stroke="#245B5B"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M74 19.645h17l-5.293 21.59h-6.419L74 19.644z"
      ></path>
    </svg>
  );
};

Innovation.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Innovation;
