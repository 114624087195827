import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import CertifiedFinancialPlanner from "./certifiedFinancialPlanner/CertifiedFinancialPlanner";
import DmTeam from "./dmTeam/DmTeam";
import FinancialAdvisorsAndPlanners from "./financialAdvisorsAndPlanners/FinancialAdvisorsAndPlanners";
import OurProcess from "./ourProcess/OurProcess";

const activeBtnProp = {
  backgroundColor: "#2196f3",
  color: "#fff",
  textTransform: "capitalize",
};

const btnProp = {
  backgroundColor: "#AEB0B4",
  color: "#fff",
  textTransform: "capitalize",
};
const AboutUs = () => {
  /**
 |--------------------------------------------------
 | handle active screen , ourProcess. dmTeam, financialPlanner, advisorAndPlanners
 |--------------------------------------------------
 */
  const [activeBtn, setActiveBtn] = React.useState("ourProcess");

  const handleActiveScreen = (name) => {
    setActiveBtn(name);
  };
  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} sm={6} md={3} lg={3}>
        <Button
          fullWidth
          style={activeBtn === "ourProcess" ? activeBtnProp : btnProp}
          onClick={() => handleActiveScreen("ourProcess")}
        >
          Our process
        </Button>
      </Grid>
      <Grid container item xs={12} sm={6} md={3} lg={3}>
        <Button
          fullWidth
          style={activeBtn === "dmTeam" ? activeBtnProp : btnProp}
          onClick={() => handleActiveScreen("dmTeam")}
        >
          D+M Team
        </Button>
      </Grid>
      <Grid container item xs={12} sm={6} md={3} lg={3}>
        <Button
          fullWidth
          style={activeBtn === "financialPlanner" ? activeBtnProp : btnProp}
          onClick={() => handleActiveScreen("financialPlanner")}
        >
          Certified Financial Planner
        </Button>
      </Grid>
      <Grid container item xs={12} sm={6} md={3} lg={3}>
        <Button
          fullWidth
          style={activeBtn === "advisorAndPlanners" ? activeBtnProp : btnProp}
          onClick={() => handleActiveScreen("advisorAndPlanners")}
        >
          Financial Advisors & Planners
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {activeBtn === "financialPlanner" && <CertifiedFinancialPlanner />}

        {activeBtn === "dmTeam" && <DmTeam />}

        {activeBtn === "advisorAndPlanners" && <FinancialAdvisorsAndPlanners />}

        {activeBtn === "ourProcess" && <OurProcess />}
      </Grid>
    </Grid>
  );
};

export default AboutUs;
