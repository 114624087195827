import React from "react";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

import Inquiries from "./inquiries/Inquiries";
import RequestPdf from "./requestPdf/RequestPdf";
import SubscribeEmail from "./subscribeEmail/SubscribeEmail";

const COLORS = ["#0088FE", "#FFBB28"];

const COLORSType2 = ["#00C49F", "#FF8042"];

const COLORSType3 = ["#F51720", "#2FF3E0"];

const EmailDashboard = () => {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */

  const InquiryEmailReducer = useSelector((state) => state.InquiryEmailReducer);

  const getEmailReducer = useSelector((state) => state.getEmailReducer);

  const getSubscribeEmailReducer = useSelector(
    (state) => state.getSubscribeEmailReducer
  );

  const getCompleted = (dataArr) => {
    return dataArr.post.filter((data) => data.isCompleted === true).length;
  };

  const getPending = (dataArr) => {
    return dataArr.post.filter((data) => data.isCompleted === false).length;
  };

  const dataInquiry = [
    { name: "Completed", value: getCompleted(InquiryEmailReducer) },
    { name: "Pending", value: getPending(InquiryEmailReducer) },
  ];

  const dataGetEmail = [
    { name: "Completed", value: getCompleted(getEmailReducer) },
    { name: "Pending", value: getPending(getEmailReducer) },
  ];

  const dataSubscribeEmail = [
    { name: "Completed", value: getCompleted(getSubscribeEmailReducer) },
    { name: "Pending", value: getPending(getSubscribeEmailReducer) },
  ];

  /**
 |--------------------------------------------------
 | handle show table, requestPdf, subscribeEmail, inquiries
 |--------------------------------------------------
 */

  const [showTable, setShowTable] = React.useState("requestPdf");

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} sm={12} md={4} lg={4}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#d0f2ff",
            cursor: "pointer",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
          }}
          spacing={2}
          onClick={() => setShowTable("requestPdf")}
        >
          <Grid container item justifyContent={"space-between"} xs={12}>
            <Typography variant="h5">Request for Pdf</Typography>
          </Grid>

          <Grid container item xs={12} sm={12} md={6} lg={6}>
            <ResponsiveContainer width="100%" height={170}>
              <PieChart width={"100%"} height={170}>
                <Pie
                  data={dataGetEmail}
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={40}
                  outerRadius={66}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {dataGetEmail.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSType2[index % COLORSType2.length]}
                    />
                  ))}
                </Pie>{" "}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid
            container
            item
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Typography variant="body1" style={{ color: "#04297a" }}>
              All Request
            </Typography>
            <Typography variant="h5" style={{ color: "#04297a" }}>
              {getEmailReducer.post.length}
            </Typography>

            <Typography variant="body1" style={{ color: "#04297a" }}>
              Completed
            </Typography>
            <Typography variant="h5" style={{ color: "#04297a" }}>
              {
                getEmailReducer.post.filter((data) => data.isCompleted === true)
                  .length
              }
            </Typography>

            <Typography variant="body1" style={{ color: "#04297a" }}>
              Pending
            </Typography>
            <Typography variant="h5" style={{ color: "#04297a" }}>
              {
                getEmailReducer.post.filter((data) => data.isCompleted !== true)
                  .length
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={4} lg={4}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#c8facd",
            cursor: "pointer",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
          }}
          spacing={2}
          onClick={() => setShowTable("subscribeEmail")}
        >
          <Grid container item justifyContent={"space-between"} xs={12}>
            <Typography variant="h5">Subscribe email</Typography>
          </Grid>

          <Grid container item xs={12} sm={12} md={6} lg={6}>
            <ResponsiveContainer width="100%" height={"100%"}>
              <PieChart width={"100%"} height={"100%"}>
                <Pie
                  data={dataSubscribeEmail}
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={40}
                  outerRadius={66}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {dataSubscribeEmail.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>{" "}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid
            container
            item
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Typography variant="body1" style={{ color: "#005249" }}>
              All Request
            </Typography>
            <Typography variant="h5" style={{ color: "#005249" }}>
              {getSubscribeEmailReducer.post.length}
            </Typography>

            <Typography variant="body1" style={{ color: "#005249" }}>
              Completed
            </Typography>
            <Typography variant="h5" style={{ color: "#005249" }}>
              {
                getSubscribeEmailReducer.post.filter(
                  (data) => data.isCompleted === true
                ).length
              }
            </Typography>

            <Typography variant="body1" style={{ color: "#005249" }}>
              Pending
            </Typography>
            <Typography variant="h5" style={{ color: "#005249" }}>
              {
                getSubscribeEmailReducer.post.filter(
                  (data) => data.isCompleted !== true
                ).length
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={4} lg={4}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#fff7cd",
            cursor: "pointer",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
          }}
          spacing={2}
          onClick={() => setShowTable("inquiries")}
        >
          <Grid container item justifyContent={"space-between"} xs={12}>
            <Typography variant="h5">Inquiries</Typography>
          </Grid>

          <Grid container item xs={12} sm={12} md={6} lg={6}>
            <ResponsiveContainer width="100%" height={170}>
              <PieChart width={"100%"} height={170}>
                <Pie
                  data={dataInquiry}
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={40}
                  outerRadius={66}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {dataInquiry.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSType3[index % COLORSType3.length]}
                    />
                  ))}
                </Pie>{" "}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
          <Grid
            container
            item
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Typography variant="body1" style={{ color: "#7a4f01" }}>
              All Request
            </Typography>
            <Typography variant="h5" style={{ color: "#7a4f01" }}>
              {InquiryEmailReducer.post.length}
            </Typography>

            <Typography variant="body1" style={{ color: "#7a4f01" }}>
              Completed
            </Typography>
            <Typography variant="h5" style={{ color: "#7a4f01" }}>
              {
                InquiryEmailReducer.post.filter(
                  (data) => data.isCompleted === true
                ).length
              }
            </Typography>

            <Typography variant="body1" style={{ color: "#7a4f01" }}>
              Pending
            </Typography>
            <Typography variant="h5" style={{ color: "#7a4f01" }}>
              {
                InquiryEmailReducer.post.filter(
                  (data) => data.isCompleted !== true
                ).length
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        {showTable === "requestPdf" && <RequestPdf />}
        {showTable === "subscribeEmail" && <SubscribeEmail />}
        {showTable === "inquiries" && <Inquiries />}
      </Grid>
    </Grid>
  );
};

export default EmailDashboard;
