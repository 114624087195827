import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DMFinancialServicesLogo from "../../../../assets/img/DM Financial Services.png";

const Topbar = ({
  themeMode,
  themeToggler,

  onSidebarOpen,
}) => {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Box marginRight={{ xs: 1, sm: 2 }}>
          <IconButton onClick={onSidebarOpen} aria-label="Menu">
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          alignItems="baseline"
          component="a"
          underline="none"
          href="/"
          title="D+M Financial Services"
          height={{ xs: 28, md: 32 }}
          width={45}
        >
          <img src={DMFinancialServicesLogo} alt="DM-financial-services" />
        </Box>
      </Box>
      <Box display="flex" alignItems={"center"}>
        <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
          <Box
            sx={{ display: { md: "none", lg: "flex" }, alignItems: "center" }}
          >
            <Button
              component={Link}
              to="/"
              style={{
                outline: "none",
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              Home
            </Button>
            <Box className="dropdown">
              <Button
                className="dropbtn"
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                }}
              >
                Services
              </Button>
              <div
                className="dropdown-content"
                style={{ backgroundColor: theme.palette.background.paper }}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/cash-Flow-Management"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Cash Flow Management
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/Debt-Advice"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Debt Advice
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/Estate-Planning"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Estate Planning
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/Investment-Advice"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Investment Advice
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/Lifestyle-and-Family-Protection"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Lifestyle and Family Protection
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/Retirement-Planning"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Retirement Planning
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/SMSF-Advice-and-Setup"
                    style={{ color: theme.palette.text.primary }}
                  >
                    SMSF Advice and Setup
                  </ListItemButton>
                </ListItem>
              </div>
            </Box>

            <Button
              component={Link}
              to="/client-feedback"
              style={{
                outline: "none",
                textDecoration: "none",
                color: theme.palette.text.primary,
                margin: "0px 10px",
              }}
            >
              Client Feedback
            </Button>
            <Box className="dropdown">
              <Button
                className="dropbtn"
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  outline: "none",
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                }}
              >
                About us
              </Button>
              <div
                className="dropdown-content"
                style={{ backgroundColor: theme.palette.background.paper }}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/our-process"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Our Process
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/dm-team"
                    style={{ color: theme.palette.text.primary }}
                  >
                    D+M Team
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/talk-to-a-certified-financial-planner"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Talk to Certified Financial Planner
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/independent-financial-adviser"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Independent Financial Advisors & Planners
                  </ListItemButton>
                </ListItem>
              </div>
            </Box>

            <Box className="dropdown">
              <Button
                className="dropbtn"
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  outline: "none",
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                }}
              >
                Knowledge Center
              </Button>
              <div
                className="dropdown-content"
                style={{ backgroundColor: theme.palette.background.paper }}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    component={"a"}
                    href="https://dmfinancialservices.financialknowledgecentre.com.au/kchome.php?page=modules"
                    target="_blank"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Modules
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={"a"}
                    href="https://dmfinancialservices.financialknowledgecentre.com.au/kcarticles.php"
                    target="_blank"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Articles
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={"a"}
                    href="https://dmfinancialservices.financialknowledgecentre.com.au/kcvideos.php?id=1272"
                    target="_blank"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Videos
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={"a"}
                    href="https://dmfinancialservices.financialknowledgecentre.com.au/kcactivities.php"
                    target="_blank"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Life Events
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={"a"}
                    href="https://dmfinancialservices.financialknowledgecentre.com.au/kccalculators.php"
                    target="_blank"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Calculator
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={"a"}
                    href="https://dmfinancialservices.financialknowledgecentre.com.au/kcquizzes.php"
                    target="_blank"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Quiz
                  </ListItemButton>
                </ListItem>
              </div>
            </Box>

            <Button
              component={Link}
              to="/blog"
              style={{
                outline: "none",
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              Blog
            </Button>
            <Button
              component={Link}
              to="/contact-Us"
              style={{
                outline: "none",
                textDecoration: "none",
                color: theme.palette.text.primary,
                marginRight: "10px",
              }}
            >
              Contact us
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              component="a"
              target="blank"
              href="tel:0413 698 770"
              size="large"
            >
              <Box display={"flex"} alignItems="center" marginRight={2}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V6C3 14.284 9.716 21 18 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V15.721C21.0001 15.511 20.934 15.3064 20.8112 15.136C20.6885 14.9657 20.5152 14.8383 20.316 14.772L15.823 13.274C15.5947 13.1981 15.3466 13.2071 15.1244 13.2993C14.9021 13.3915 14.7205 13.5607 14.613 13.776L13.483 16.033C11.0345 14.9267 9.07332 12.9655 7.967 10.517L10.224 9.387C10.4393 9.27945 10.6085 9.0979 10.7007 8.87564C10.7929 8.65339 10.8019 8.40534 10.726 8.177L9.228 3.684C9.16171 3.48496 9.03449 3.3118 8.86436 3.18905C8.69422 3.0663 8.48979 3.00016 8.28 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579Z"
                    stroke="#F7F9FC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Box display={"flex"} alignItems="center">
                0413 698 770
              </Box>
            </Button>
          </Box>
        </Box>
        <Box marginLeft={3}>
          <IconButton
            onClick={() => themeToggler()}
            aria-label="Dark mode toggler"
            color={themeMode === "light" ? "primary" : "secondary"}
          >
            {themeMode === "light" ? (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                />
              </svg>
            ) : (
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            )}
          </IconButton>
        </Box>
        <Box
          marginLeft={1}
          marginTop={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Link to="/login">
            <AdminPanelSettingsIcon
              style={{ color: theme.palette.text.primary }}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
