import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationMessage = (props) => {
  const {
    openDialog,
    handleCloseDialog,
    handleConfirmationAction,
    message,
    loading,
  } = props;

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle
        style={{
          background: "linear-gradient(to right, #000046, #1cb5e0)",
          color: "#fff",
        }}
      >
        Confirmation
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginTop: "1rem" }}>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size={"medium"}
          variant={"outlined"}
          style={{ color: "#000046", marginRight: 10 }}
          onClick={handleCloseDialog}
        >
          No
        </Button>
        <Button
          size={"medium"}
          variant={"contained"}
          onClick={handleConfirmationAction}
          style={{ backgroundColor: "#000046", color: "#fff" }}
        >
          {loading ? (
            <CircularProgress style={{ color: "#fff" }} size={25} />
          ) : (
            "Yes"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationMessage;
