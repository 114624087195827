export const CREATE_CONTENT_REQUEST = 'CREATE_CONTENT_REQUEST'
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS'
export const CREATE_CONTENT_ERROR = 'CREATE_CONTENT_ERROR'

export const UPDATE_HOME_CONTENT_REQUEST = 'UPDATE_HOME_CONTENT_REQUEST'
export const UPDATE_HOME_CONTENT_SUCCESS = 'UPDATE_HOME_CONTENT_SUCCESS'
export const UPDATE_HOME_CONTENT_ERROR = 'UPDATE_HOME_CONTENT_ERROR'

export const GET_ALL_CONTENT_REQUEST = 'GET_ALL_CONTENT_REQUEST'
export const GET_ALL_CONTENT_SUCCESS = 'GET_ALL_CONTENT_SUCCESS'
export const GET_ALL_CONTENT_ERROR = 'GET_ALL_CONTENT_ERROR'

export const GET_ALL_Frequently_Questions_REQUEST = 'GET_ALL_Frequently_Questions_REQUEST'
export const GET_ALL_Frequently_Questions_SUCCESS = 'GET_ALL_Frequently_Questions_SUCCESS'
export const GET_ALL_Frequently_Questions_ERROR = 'GET_ALL_Frequently_Questions_ERROR'

export const UPDATE_Frequently_Questions_REQUEST = 'UPDATE_Frequently_Questions_REQUEST'
export const UPDATE_Frequently_Questions_SUCCESS = 'UPDATE_Frequently_Questions_SUCCESS'
export const UPDATE_Frequently_Questions_ERROR = 'UPDATE_Frequently_Questions_ERROR'

export const GET_ALL_ABOUT_US_REQUEST = 'GET_ALL_ABOUT_US_REQUEST'
export const GET_ALL_ABOUT_US_SUCCESS = 'GET_ALL_ABOUT_US_SUCCESS'
export const GET_ALL_ABOUT_US_ERROR = 'GET_ALL_ABOUT_US_ERROR'

export const UPDATE_ABOUT_US_REQUEST = 'UPDATE_ABOUT_US_REQUEST'
export const UPDATE_ABOUT_US_SUCCESS = 'UPDATE_ABOUT_US_SUCCESS'
export const UPDATE_ABOUT_US_ERROR = 'UPDATE_ABOUT_US_ERROR'

export const GET_ALL_BLOG_POST_REQUEST = 'GET_ALL_BLOG_POST_REQUEST'
export const GET_ALL_BLOG_POST_SUCCESS = 'GET_ALL_BLOG_POST_SUCCESS'
export const GET_ALL_BLOG_POST_ERROR = 'GET_ALL_BLOG_POST_ERROR'

export const CREATE_BLOG_POST_REQUEST = 'CREATE_BLOG_POST_REQUEST'
export const CREATE_BLOG_POST_SUCCESS = 'CREATE_BLOG_POST_SUCCESS'
export const CREATE_BLOG_POST_ERROR = 'CREATE_BLOG_POST_ERROR'

export const UPDATE_BLOG_POST_REQUEST = 'UPDATE_BLOG_POST_REQUEST'
export const UPDATE_BLOG_POST_SUCCESS = 'UPDATE_BLOG_POST_SUCCESS'
export const UPDATE_BLOG_POST_ERROR = 'UPDATE_BLOG_POST_ERROR'

export const GET_DM_TEAM_DETAILS_REQUEST = 'GET_DM_TEAM_DETAILS_REQUEST'
export const GET_DM_TEAM_DETAILS_SUCCESS = 'GET_DM_TEAM_DETAILS_SUCCESS'
export const GET_DM_TEAM_DETAILS_ERROR = 'GET_DM_TEAM_DETAILS_ERROR'

export const UPDATE_DM_TEAM_DETAILS_REQUEST = 'UPDATE_DM_TEAM_DETAILS_REQUEST'
export const UPDATE_DM_TEAM_DETAILS_SUCCESS = 'UPDATE_DM_TEAM_DETAILS_SUCCESS'
export const UPDATE_DM_TEAM_DETAILS_ERROR = 'UPDATE_DM_TEAM_DETAILS_ERROR'

export const GET_CASH_FLOW_MANAGEMENT_REQUEST = 'GET_CASH_FLOW_MANAGEMENT_REQUEST'
export const GET_CASH_FLOW_MANAGEMENT_SUCCESS = 'GET_CASH_FLOW_MANAGEMENT_SUCCESS'
export const GET_CASH_FLOW_MANAGEMENT_ERROR = 'GET_CASH_FLOW_MANAGEMENT_ERROR'

export const UPDATE_CASH_FLOW_MANAGEMENT_REQUEST = 'UPDATE_CASH_FLOW_MANAGEMENT_REQUEST'
export const UPDATE_CASH_FLOW_MANAGEMENT_SUCCESS = 'UPDATE_CASH_FLOW_MANAGEMENT_SUCCESS'
export const UPDATE_CASH_FLOW_MANAGEMENT_ERROR = 'UPDATE_CASH_FLOW_MANAGEMENT_ERROR'

export const GET_OUR_PROCESS_REQUEST = 'GET_OUR_PROCESS_REQUEST'
export const GET_OUR_PROCESS_SUCCESS = 'GET_OUR_PROCESS_SUCCESS'
export const GET_OUR_PROCESS_ERROR = 'GET_OUR_PROCESS_ERROR'

export const UPDATE_OUR_PROCESS_REQUEST = 'UPDATE_OUR_PROCESS_REQUEST'
export const UPDATE_OUR_PROCESS_SUCCESS = 'UPDATE_OUR_PROCESS_SUCCESS'
export const UPDATE_OUR_PROCESS_ERROR = 'UPDATE_OUR_PROCESS_ERROR'

export const GET_CERTIFIED_FINANCIAL_PLANNER_REQUEST = 'GET_CERTIFIED_FINANCIAL_PLANNER_REQUEST'
export const GET_CERTIFIED_FINANCIAL_PLANNER_SUCCESS = 'GET_CERTIFIED_FINANCIAL_PLANNER_SUCCESS'
export const GET_CERTIFIED_FINANCIAL_PLANNER_ERROR = 'GET_CERTIFIED_FINANCIAL_PLANNER_ERROR'

export const UPDATE_CERTIFIED_FINANCIAL_PLANNER_REQUEST = 'UPDATE_CERTIFIED_FINANCIAL_PLANNER_REQUEST'
export const UPDATE_CERTIFIED_FINANCIAL_PLANNER_SUCCESS = 'UPDATE_CERTIFIED_FINANCIAL_PLANNER_SUCCESS'
export const UPDATE_CERTIFIED_FINANCIAL_PLANNER_ERROR = 'UPDATE_CERTIFIED_FINANCIAL_PLANNER_ERROR'

export const GET_CLIENT_FEEDBACK_REQUEST = 'GET_CLIENT_FEEDBACK_REQUEST'
export const GET_CLIENT_FEEDBACK_SUCCESS = 'GET_CLIENT_FEEDBACK_SUCCESS'
export const GET_CLIENT_FEEDBACK_ERROR = 'GET_CLIENT_FEEDBACK_ERROR'

export const CREATE_CLIENT_FEEDBACK_REQUEST = 'CREATE_CLIENT_FEEDBACK_REQUEST'
export const CREATE_CLIENT_FEEDBACK_SUCCESS = 'CREATE_CLIENT_FEEDBACK_SUCCESS'
export const CREATE_CLIENT_FEEDBACK_ERROR = 'CREATE_CLIENT_FEEDBACK_ERROR'

export const UPDATE_CLIENT_FEEDBACK_REQUEST = 'UPDATE_CLIENT_FEEDBACK_REQUEST'
export const UPDATE_CLIENT_FEEDBACK_SUCCESS = 'UPDATE_CLIENT_FEEDBACK_SUCCESS'
export const UPDATE_CLIENT_FEEDBACK_ERROR = 'UPDATE_CLIENT_FEEDBACK_ERROR'

export const CREATE_INQUIRY_MAIL_REQUEST = 'CREATE_INQUIRY_MAIL_REQUEST'
export const CREATE_INQUIRY_MAIL_SUCCESS = 'CREATE_INQUIRY_MAIL_SUCCESS'
export const CREATE_INQUIRY_MAIL_ERROR = 'CREATE_INQUIRY_MAIL_ERROR'

export const INQUIRY_MAIL_REQUEST = 'INQUIRY_MAIL_REQUEST'
export const INQUIRY_MAIL_SUCCESS = 'INQUIRY_MAIL_SUCCESS'
export const INQUIRY_MAIL_ERROR = 'INQUIRY_MAIL_ERROR'

export const UPDATE_INQUIRY_MAIL_REQUEST = 'UPDATE_INQUIRY_MAIL_REQUEST'
export const UPDATE_INQUIRY_MAIL_SUCCESS = 'UPDATE_INQUIRY_MAIL_SUCCESS'
export const UPDATE_INQUIRY_MAIL_ERROR = 'UPDATE_INQUIRY_MAIL_ERROR'

export const DELETE_INQUIRY_MAIL_REQUEST = 'DELETE_INQUIRY_MAIL_REQUEST'
export const DELETE_INQUIRY_MAIL_SUCCESS = 'DELETE_INQUIRY_MAIL_SUCCESS'
export const DELETE_INQUIRY_MAIL_ERROR = 'DELETE_INQUIRY_MAIL_ERROR'

export const CREATE_PDF_MAIL_REQUEST = 'CREATE_PDF_MAIL_REQUEST'
export const CREATE_PDF_MAIL_SUCCESS = 'CREATE_PDF_MAIL_SUCCESS'
export const CREATE_PDF_MAIL_ERROR = 'CREATE_PDF_MAIL_ERROR'

export const GET_PDF_MAIL_REQUEST = 'GET_PDF_MAIL_REQUEST'
export const GET_PDF_MAIL_SUCCESS = 'GET_PDF_MAIL_SUCCESS'
export const GET_PDF_MAIL_ERROR = 'GET_PDF_MAIL_ERROR'

export const UPDATE_PDF_MAIL_REQUEST = 'UPDATE_PDF_MAIL_REQUEST'
export const UPDATE_PDF_MAIL_SUCCESS = 'UPDATE_PDF_MAIL_SUCCESS'
export const UPDATE_PDF_MAIL_ERROR = 'UPDATE_PDF_MAIL_ERROR'

export const DELETE_PDF_MAIL_REQUEST = 'DELETE_PDF_MAIL_REQUEST'
export const DELETE_PDF_MAIL_SUCCESS = 'DELETE_PDF_MAIL_SUCCESS'
export const DELETE_PDF_MAIL_ERROR = 'DELETE_PDF_MAIL_ERROR'

export const CREATE_SUBSCRIBE_MAIL_REQUEST = 'CREATE_SUBSCRIBE_MAIL_REQUEST'
export const CREATE_SUBSCRIBE_MAIL_SUCCESS = 'CREATE_SUBSCRIBE_MAIL_SUCCESS'
export const CREATE_SUBSCRIBE_MAIL_ERROR = 'CREATE_SUBSCRIBE_MAIL_ERROR'

export const SUBSCRIBE_MAIL_REQUEST = 'SUBSCRIBE_MAIL_REQUEST'
export const SUBSCRIBE_MAIL_SUCCESS = 'SUBSCRIBE_MAIL_SUCCESS'
export const SUBSCRIBE_MAIL_ERROR = 'SUBSCRIBE_MAIL_ERROR'

export const UPDATE_SUBSCRIBE_MAIL_REQUEST = 'UPDATE_SUBSCRIBE_MAIL_REQUEST'
export const UPDATE_SUBSCRIBE_MAIL_SUCCESS = 'UPDATE_SUBSCRIBE_MAIL_SUCCESS'
export const UPDATE_SUBSCRIBE_MAIL_ERROR = 'UPDATE_SUBSCRIBE_MAIL_ERROR'

export const DELETE_SUBSCRIBE_MAIL_REQUEST = 'DELETE_SUBSCRIBE_MAIL_REQUEST'
export const DELETE_SUBSCRIBE_MAIL_SUCCESS = 'DELETE_SUBSCRIBE_MAIL_SUCCESS'
export const DELETE_SUBSCRIBE_MAIL_ERROR = 'DELETE_SUBSCRIBE_MAIL_ERROR'