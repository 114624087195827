import React from "react";
import { Switch, Route } from "react-router-dom";
import WithLayout from "./WithLayout";
// Available layouts
import Main from "./layouts/Main/Main";
import LoginSimple from "./authPages/Login";

// Landing pages
import HomeNew from "./landingPages/HomeNew/HomeNew";
import Contact from "./views/Contact/Contact";
import FinancialAdvisorsAndPlanners from "./views/aboutUs/financialAdvisorsAndPlanners/FinancialAdvisorsAndPlanners";
import CertifiedFinancialPlanner from "./views/aboutUs/certifiedFinancialPlanner/CertifiedFinancialPlanner";
import DMTeam from "./views/aboutUs/dmTeam/DMTeam";
import OurProcess from "./views/aboutUs/ourProcess/OurProcess";
import Blog from "./views/blog/blog/Blog";
import CashFlowManagement from "./views/services/cashFlowManagement/CashFlowManagement";
import DebtAdviceMortgageBroker from "./views/services/debtAdviceMortgageBroker/DebtAdviceMortgageBroker";
import EstatePlanning from "./views/services/estatePlanning/EstatePlanning";
import IndependentFinancialAdvice from "./views/services/IndependentFinancialAdvice/IndependentFinancialAdvice";
import LifestyleAndFamilyProtection from "./views/services/lifestyleAndFamilyProtection/LifestyleAndFamilyProtection";
import RetirementPlanning from "./views/services/retirementPlanning/RetirementPlanning";
import SmsfAdviceAndSetup from "./views/services/smsfAdviceAndSetup/SmsfAdviceAndSetup";
import ClientFeedback from "./views/clientFeedback/ClientFeedback";
const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={HomeNew} layout={Main} />
        )}
      />
      <Route
        exact
        path="/login"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={LoginSimple} layout={Main} />
        )}
      />

      <Route
        exact
        path="/contact-Us"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Contact} layout={Main} />
        )}
      />
      <Route
        exact
        path="/independent-financial-adviser"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={FinancialAdvisorsAndPlanners}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/talk-to-a-certified-financial-planner"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={CertifiedFinancialPlanner}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/dm-team"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={DMTeam} layout={Main} />
        )}
      />
      <Route
        exact
        path="/our-process"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={OurProcess} layout={Main} />
        )}
      />
      <Route
        exact
        path="/blog"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Blog} layout={Main} />
        )}
      />

      <Route
        exact
        path="/cash-Flow-Management"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={CashFlowManagement}
            layout={Main}
          />
        )}
      />

      <Route
        exact
        path="/Debt-Advice"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={DebtAdviceMortgageBroker}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/Estate-Planning"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={EstatePlanning}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/Investment-Advice"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={IndependentFinancialAdvice}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/Lifestyle-and-Family-Protection"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LifestyleAndFamilyProtection}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/Retirement-Planning"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={RetirementPlanning}
            layout={Main}
          />
        )}
      />
      <Route
        exact
        path="/SMSF-Advice-and-Setup"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={SmsfAdviceAndSetup}
            layout={Main}
          />
        )}
      />

      <Route
        exact
        path="/client-feedback"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ClientFeedback}
            layout={Main}
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
