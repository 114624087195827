import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { colors } from '@mui/material';
import blogger from '../../../../assets/img/blogger.png';

const buttonClass = {
  margin: '0.5rem 1rem',
  backgroundColor: ' #E5F8EC',
  color: '#0F9D58',
};

const Posts = (props) => {
  const { handleBlogModalOpen } = props;
  const theme = useTheme();

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const allBlogPostReducer = useSelector((state) => state.allBlogPostReducer);

  const { blogPost } = allBlogPostReducer;

  /**
 |--------------------------------------------------
 | filter blog post
 |--------------------------------------------------
 */
  const [filterPost, setFilterPost] = React.useState(blogPost);

  const handleBlogPost = (type) => {
    const post = blogPost && blogPost.filter((data) => data.tag === type);
    setFilterPost(post);
  };

  return (
    <Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 500,
          left: 0,
        }}
      >
        <svg
          width="83"
          height="145"
          viewBox="0 0 83 145"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="72.5" r="72.5" fill="url(#paint0_linear_399_166)" />
          <defs>
            <linearGradient
              id="paint0_linear_399_166"
              x1="60.2745"
              y1="21.9495"
              x2="-31.8471"
              y2="116.288"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#11B735" />
              <stop offset="1" stop-color="#8AF7A2" />
            </linearGradient>
          </defs>
        </svg>
      </Box>

      <Box
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 100,
          right: 0,
        }}
      >
        <svg
          width="446"
          height="527"
          viewBox="0 0 446 527"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="263.5" cy="263.5" r="263.5" fill="url(#paint0_linear_400_167)" />
          <defs>
            <linearGradient
              id="paint0_linear_400_167"
              x1="444.404"
              y1="79.7752"
              x2="109.59"
              y2="422.648"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#11B735" />
              <stop offset="1" stop-color="#8AF7A2" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <Button style={buttonClass} onClick={() => setFilterPost(blogPost)}>
            All
          </Button>
          <Button style={buttonClass} onClick={() => handleBlogPost('Blog')}>
            Blog
          </Button>
          <Button style={buttonClass} onClick={() => handleBlogPost('Retirement')}>
            Retirement
          </Button>
          <Button style={buttonClass} onClick={() => handleBlogPost('Superannuation')}>
            Superannuation
          </Button>
          <Button style={buttonClass} onClick={() => handleBlogPost('Uncategorized')}>
            Uncategorized
          </Button>
        </Grid>

        {filterPost.map((item, i) => (
          <Grid item xs={12} key={i} data-aos={'fade-up'}>
            <Box
              component={Card}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              variant={'outlined'}
              sx={{
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                minWidth={200}
                minHeight={{ xs: 200, md: 'auto' }}
                color={theme.palette.primary.dark}
                bgcolor={`${theme.palette.primary.light}22`}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}
              >
                <img src={blogger} alt={'blog_img'} style={{ width: 100, height: 100 }} />
              </Box>
              <CardContent style={{ width: '100%' }}>
                <Box display={'flex'} justifyContent={'space-between'} align={'center'}>
                  <Box
                    paddingX={1}
                    color={theme.palette.common.white}
                    bgcolor={colors.red[500]}
                    borderRadius={1}
                  >
                    <Typography color={'inherit'} align={'center'} variant={'subtitle2'}>
                      {item.tag}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={'subtitle2'}>
                      {moment(item.date).format('DD/MM/YYYY')} - {item.duration} min
                    </Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  style={{
                    maxHeight: 205,
                    overflow: 'hidden',
                  }}
                >
                  <Typography variant={'h6'} gutterBottom>
                    <Box fontWeight={600}>{item.title}</Box>
                  </Typography>
                  {item.description.map((subData, subIndex) => {
                    return (
                      <Box key={subIndex}>
                        <Typography
                          variant={subData.description}
                          style={{ fontWeight: subData.fontWeight }}
                        >
                          {subData.description}
                        </Typography>

                        {subData.isBr && <br />}
                      </Box>
                    );
                  })}
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button size={'large'} onClick={() => handleBlogModalOpen(item)}>
                    Read more
                  </Button>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Posts;
