import { HANDLE_NOTIFICATION } from "../types/sharedTypes"

export const handleNotification = (snackText, snackVariant) => async (dispatch, getState) => {

    dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
            snackText: snackText,
            snackVariant: snackVariant,
        },
    });
};