import axios from 'axios';
import store from '../redux/store';
import { USER_LOGOUT } from '../redux/types/authTypes';

//const baseURL = "http://localhost:5888/api/"
const baseURL = 'https://dmfinancebackend.azurewebsites.net/api/';
const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired
 logout the user if the token has expired
**/

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.data.msg === 'Token is not valid') {
      store.dispatch({ type: USER_LOGOUT });
    }
    return Promise.reject(err);
  }
);

export default api;
