import { HANDLE_MODAL } from "../types/sharedTypes"

/**
 |--------------------------------------------------
 |@param {modalStatus, modalName}  
 |--------------------------------------------------
 */
export const handleModals = (modalStatus, modalName) => async (dispatch, getState) => {
    // type - ADD_BRAND_MODAL, 
    const modalDetails = {
        modalName: modalName,
        modalStatus: modalStatus
    }
    dispatch({
        type: HANDLE_MODAL,
        payload: modalDetails,
    })
}