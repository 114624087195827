import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Posts from "./components/Posts";
import Container from "../../../common/Container";
import Hero from "./components/Hero";
import BlogModal from "./Modals/BlogModals";
import { handleModals } from "../../../redux/actions/handleModalAction";
import dmFinancialServicesBlogs from "../../../assets/img/backgroundImg5.png";

const Blog = () => {
  const theme = useTheme();
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const modelReducer = useSelector((state) => state.modalReducer);

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const [blogDetails, setBlogDetails] = React.useState(null);

  const handleBlogModalOpen = (data) => {
    dispatch(handleModals(true, "FULL_SCREEN_MODAL"));
    setBlogDetails(data);
  };
  const handleBlogModalClose = () => {
    setBlogDetails(null);
    dispatch(handleModals(false, "FULL_SCREEN_MODAL"));
  };

  return (
    <Box>
      <BlogModal
        openDialog={modelReducer.FULL_SCREEN_MODAL.modalStatus}
        handleCloseDialog={handleBlogModalClose}
        blogDetails={blogDetails}
      />
      <Box
        minHeight={{ xs: "auto", md: "calc(40vh - 64px)" }}
        height={"auto"}
        position={"relative"}
        sx={{
          backgroundImage: `url(${dmFinancialServicesBlogs})`,
          backgroundSize: "cover",
          backgroundPosition: "center right",
          zIndex: 10,
          "&:after": {
            position: "absolute",
            content: '" "',
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: theme.palette.common.black,
            opacity: 0.3,
          },
        }}
      >
        <Box
          position={{ xs: "relative", md: "absolute" }}
          top={{ xs: 0, md: "50%" }}
          width={"100%"}
          height={"50%"}
          sx={{
            transform: { md: "translateY(-50%)" },
          }}
          zIndex={2}
        >
          <Container>
            <Hero />
          </Container>
        </Box>
      </Box>

      <Box bgcolor={theme.palette.alternate.main}>
        <Container>
          <Posts handleBlogModalOpen={handleBlogModalOpen} />
        </Container>
      </Box>
    </Box>
  );
};

Blog.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Blog;
