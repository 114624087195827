import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
// import 'scss/react-images.scss';
// import 'scss/slick-slider.scss';
import Snackbars from './common/Snackbars';

import Routes from './Routes';
import Dashboard from './dashboard/Dashboard';
import './App.css';
// import Snowfall from 'react-snowfall';
import { handleNotification } from './redux/actions/handleNotificationAction';
import {
  getAllPost,
  getAllFrequentlyQuestions,
  getAllAboutUsAction,
  getAllBlogPostAction,
  getDmTeamPostAction,
  getCashFlowPostAction,
  getOurProcessPostAction,
  getCertifiedFinancialPlannerPostAction,
  getClientFeedbackPostAction,
} from './redux/actions/contentAction';

function App() {
  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();
  const notificationReducer = useSelector((state) => state.notificationReducer);

  React.useEffect(() => {
    dispatch(getAllPost());
    dispatch(getAllFrequentlyQuestions());
    dispatch(getAllAboutUsAction());
    dispatch(getAllBlogPostAction());
    dispatch(getDmTeamPostAction());
    dispatch(getCashFlowPostAction());
    dispatch(getOurProcessPostAction());
    dispatch(getCertifiedFinancialPlannerPostAction());
    dispatch(getClientFeedbackPostAction());
  }, []);

  /**
 |--------------------------------------------------
 | reset notification
 |--------------------------------------------------
 */
  const handleReset = () => {
    dispatch(handleNotification('', ''));
  };

  return (
    <BrowserRouter>
      <Snackbars
        snackbarMessage={notificationReducer.snackText}
        severity={notificationReducer.snackVariant}
        handleReset={handleReset}
      />
      {/* <Snowfall
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: 1000,
        }}
        color={'#d0eceb'}
        snowflakeCount={250}
      /> */}
      <Switch>
        <Route path="/dashboard" render={(props) => <Dashboard />} />
        <Route path="/" render={(props) => <Routes />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
