import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InnovationIllustration from '../../../../../svg/illustrations/Innovation';
import { baseURL } from '../../../../../utils/API_URLS';

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const allAboutUsReducer = useSelector((state) => state.allAboutUsReducer);

  const { aboutUs } = allAboutUsReducer;

  const INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS =
    aboutUs &&
    aboutUs.filter((data) => data.title === 'INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS');

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography color={'primary'} variant="h5">
              {INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS &&
                INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS[0].desc_1}
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="inherit" component="p" color="textSecondary">
              {INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS &&
                INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS[0].desc_2}
            </Typography>
            <br />
            <Typography variant={'inherit'} component="p" color="textSecondary">
              {INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS &&
                INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS[0].desc_3}
            </Typography>
            <br />
            <Typography variant="inherit" component="p" color="textSecondary">
              {INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS &&
                INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS[0].desc_4}
            </Typography>
            <br />
            <Typography variant="inherit" component="p" color="textSecondary">
              {INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS &&
                INDEPENDENT_FINANCIAL_ADVISORS_AND_PLANNERS[0].desc_5}
            </Typography>

            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth={isMd ? false : true}
              component={'a'}
              href={`${baseURL}/file/RoyalCommission.pdf`}
              target="_blank"
            >
              More details - PDF view
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={'100%'} width={'100%'} maxHeight={500}>
            <InnovationIllustration width={'100%'} height={'100%'} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
