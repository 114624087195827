import api from './api';

const setAuthToken = token => {


    if (token) {
        api.defaults.headers.common['Authorization'] = token;
        localStorage.setItem('dmFinancialToken', JSON.stringify(token));
    } else {
        delete api.defaults.headers.common['Authorization'];
        localStorage.removeItem('dmFinancialToken');
    }
};

export default setAuthToken;
