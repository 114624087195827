import React from "react";
import { Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";

import TextError from "./TextError";
const FormikInput = (props) => {
  const {
    label,
    type,
    name,
    error,
    shrink,
    helperText,
    disabled,
    rows,
    multiline,
    ...rest
  } = props;

  FormikInput.defaultProps = {
    rows: 1,
    multiline: false,
  };
  return (
    <Field name={name}>
      {({ field, form }) => (
        <>
          <TextField
            style={{ backgroundColor: "#fff" }}
            error={error}
            margin="dense"
            variant="outlined"
            id={name}
            label={label}
            type={type}
            inputProps={{
              type: type,
            }}
            fullWidth
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            InputLabelProps={{
              shrink: shrink,
            }}
            helperText={helperText}
            {...rest}
            {...field}
          />
          <ErrorMessage component={TextError} name={name} />
        </>
      )}
    </Field>
  );
};

export default FormikInput;
