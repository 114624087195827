import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import FormikInput from "../../../../common/formik/FormikInput";
import FormikSelect from "../../../../common/formik/FormikSelect";
import CommonCheckBox from "../../../../common/formik/CommonCheckBox";

import { updateOurProcessPostAction } from "../../../../redux/actions/contentAction";

import { variantType } from "../../../../utils/dropDownData";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionModal = (props) => {
  const { openDialog, handleCloseDialog, description1 } = props;

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  const updateOurProcessPostReducer = useSelector(
    (state) => state.updateOurProcessPostReducer
  );

  const { loading } = updateOurProcessPostReducer;

  const initialValues = {
    title: description1 ? description1.title : "",
    username: description1 ? description1.username : "",
    media: description1 ? description1.media : "",
    description: description1
      ? description1.description
      : [
          {
            variant: "body2",
            description: "",
            fontWeight: 400,
            isBr: true,
          },
        ],
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Required"),
  });

  const onSubmit = (values, submitProps) => {
    dispatch(updateOurProcessPostAction(values, description1._id));
  };

  // handle add or remove secondary description arr
  const [descriptionArr, setDescriptionArr] = React.useState([0]);
  React.useEffect(() => {
    description1 && setDescriptionArr(description1.description);
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      {description1 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form noValidate>
                <DialogTitle
                  style={{
                    background: "linear-gradient(to right, #000046, #1cb5e0)",
                    color: "#fff",
                  }}
                >
                  Our Process
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormikInput
                        error={formik.errors.title && formik.touched.title}
                        name={"title"}
                        type="text"
                        label={"Title"}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FieldArray name={"description"}>
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { description } = values;

                          return (
                            <div style={{ width: "100%" }}>
                              {description &&
                                description.map((description, index) => (
                                  <Grid container spacing={1} key={index}>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      style={{ marginTop: "2rem" }}
                                      justifyContent={"flex-end"}
                                    >
                                      <AddCircleOutlineIcon
                                        style={{
                                          color: "#33CC70",
                                          fontSize: "2rem",
                                        }}
                                        onClick={() => {
                                          return (
                                            push({
                                              variant: "body2",
                                              description: "",
                                              fontWeight: 400,
                                              isBr: true,
                                            }),
                                            descriptionArr.push(1),
                                            setDescriptionArr(descriptionArr)
                                          );
                                        }}
                                      />

                                      {descriptionArr.length !== 1 && (
                                        <DeleteOutlineIcon
                                          style={{
                                            color: "red",
                                            fontSize: "2rem",
                                          }}
                                          onClick={() => {
                                            return descriptionArr.length === 1
                                              ? null
                                              : (descriptionArr.pop(index),
                                                setDescriptionArr(
                                                  descriptionArr
                                                ),
                                                remove(index));
                                          }}
                                        />
                                      )}
                                    </Grid>

                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      alignItems={"center"}
                                    >
                                      <FormikSelect
                                        error={
                                          Object.keys(formik.errors).length !==
                                            0 &&
                                          Object.keys(formik.touched).length !==
                                            0 &&
                                          formik.errors.description &&
                                          formik.touched.description &&
                                          formik.errors.description[index] &&
                                          formik.touched.description[index] &&
                                          formik.errors.description[index]
                                            .variant &&
                                          formik.touched.description[index]
                                            .variant
                                        }
                                        name={`description[${index}].variant`}
                                        options={variantType}
                                        label={"Font Variant"}
                                        selectValue="id"
                                        selectName="name"
                                        placeholder={
                                          formik.values.description[index]
                                            .variant
                                        }
                                      />
                                    </Grid>

                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      alignItems={"center"}
                                    >
                                      <FormikInput
                                        error={
                                          Object.keys(formik.errors).length !==
                                            0 &&
                                          Object.keys(formik.touched).length !==
                                            0 &&
                                          formik.errors.description &&
                                          formik.touched.description &&
                                          formik.errors.description[index] &&
                                          formik.touched.description[index] &&
                                          formik.errors.description[index]
                                            .fontWeight &&
                                          formik.touched.description[index]
                                            .fontWeight
                                        }
                                        name={`description[${index}].fontWeight`}
                                        type="number"
                                        label={"Font Weight"}
                                      />
                                    </Grid>

                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      alignItems={"center"}
                                    >
                                      <CommonCheckBox
                                        name={`description[${index}].isBr`}
                                        label={"Line Break"}
                                      />
                                    </Grid>

                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      alignItems={"center"}
                                    >
                                      <FormikInput
                                        error={
                                          Object.keys(formik.errors).length !==
                                            0 &&
                                          Object.keys(formik.touched).length !==
                                            0 &&
                                          formik.errors.description &&
                                          formik.touched.description &&
                                          formik.errors.description[index] &&
                                          formik.touched.description[index] &&
                                          formik.errors.description[index]
                                            .description &&
                                          formik.touched.description[index]
                                            .description
                                        }
                                        multiline
                                        rows={4}
                                        name={`description[${index}].description`}
                                        type="text"
                                        label={"Description"}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                            </div>
                          );
                        }}
                      </FieldArray>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    size={"large"}
                    variant={"outlined"}
                    style={{ color: "#000046", marginRight: 10 }}
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    size={"large"}
                    variant={"contained"}
                    type={"submit"}
                    style={{ backgroundColor: "#000046", color: "#fff" }}
                  >
                    {loading ? (
                      <CircularProgress style={{ color: "#fff" }} size={25} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};

export default QuestionModal;
