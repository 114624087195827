import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import teamImg1 from '../../../../assets/img/teamImg1.PNG';
import teamImg2 from '../../../../assets/img/teamImg2.PNG';
import teamImg3 from '../../../../assets/img/teamImg3.PNG';
import teamImg4 from '../../../../assets/img/teamImg4.PNG';
import teamImg5 from '../../../../assets/img/teamImg5.PNG';
import teamImg6 from '../../../../assets/img/teamImg6.PNG';

export default function DesktopMain() {
  const theme = useTheme();

  /**
|--------------------------------------------------
| redux integration
|--------------------------------------------------
*/
  const ourProcessPostReducer = useSelector((state) => state.ourProcessPostReducer);

  const { post } = ourProcessPostReducer;

  return (
    <Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 500,
          left: 0,
        }}
      >
        <svg
          width="83"
          height="145"
          viewBox="0 0 83 145"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="72.5" r="72.5" fill="url(#paint0_linear_399_166)" />
          <defs>
            <linearGradient
              id="paint0_linear_399_166"
              x1="60.2745"
              y1="21.9495"
              x2="-31.8471"
              y2="116.288"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#11B735" />
              <stop offset="1" stop-color="#8AF7A2" />
            </linearGradient>
          </defs>
        </svg>
      </Box>

      <Box
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 100,
          right: 0,
        }}
      >
        <svg
          width="446"
          height="527"
          viewBox="0 0 446 527"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="263.5" cy="263.5" r="263.5" fill="url(#paint0_linear_400_167)" />
          <defs>
            <linearGradient
              id="paint0_linear_400_167"
              x1="444.404"
              y1="79.7752"
              x2="109.59"
              y2="422.648"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D2FFC6" />
              <stop offset="1" stop-color="#8AF7A2" />
            </linearGradient>
          </defs>
        </svg>
      </Box>

      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <CardMedia
              image={teamImg3}
              sx={{
                height: 240,
                width: '100%',
              }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Box
                borderRadius={'50%'}
                sx={{ width: 56, height: 56 }}
                textAlign={'center'}
                display={'flex'}
                justifyContent={'center'}
                bgcolor={theme.palette.primary.main}
              >
                <Typography style={{ fontSize: '2.3rem' }}>1</Typography>
              </Box>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {post && post.length !== 0 && post[0].title}
                  </Box>
                  {post &&
                    post.length !== 0 &&
                    post[0].description.map((data, desIndex) => (
                      <Typography
                        align={'left'}
                        variant={data.variant}
                        color="textSecondary"
                        key={desIndex}
                      >
                        {data.description}
                      </Typography>
                    ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <CardMedia
              image={teamImg4}
              sx={{
                height: 240,
                width: '100%',
              }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Box
                borderRadius={'50%'}
                sx={{ width: 56, height: 56 }}
                textAlign={'center'}
                display={'flex'}
                justifyContent={'center'}
                bgcolor={theme.palette.primary.main}
              >
                <Typography style={{ fontSize: '2.3rem' }}>2</Typography>
              </Box>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {post && post.length !== 0 && post[1].title}
                  </Box>
                  {post &&
                    post.length !== 0 &&
                    post[1].description.map((data, desIndex) => (
                      <Typography
                        align={'left'}
                        variant={data.variant}
                        color="textSecondary"
                        key={desIndex}
                      >
                        {data.description}
                      </Typography>
                    ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <CardMedia
              image={teamImg2}
              sx={{
                height: 240,
                width: '100%',
              }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Box
                borderRadius={'50%'}
                sx={{ width: 56, height: 56 }}
                textAlign={'center'}
                display={'flex'}
                justifyContent={'center'}
                bgcolor={theme.palette.primary.main}
              >
                <Typography style={{ fontSize: '2.3rem' }}>3</Typography>
              </Box>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {post && post.length !== 0 && post[2].title}
                  </Box>
                  {post &&
                    post.length !== 0 &&
                    post[2].description.map((data, desIndex) => (
                      <Typography
                        align={'left'}
                        variant={data.variant}
                        color="textSecondary"
                        key={desIndex}
                      >
                        {data.description}
                      </Typography>
                    ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <CardMedia
              image={teamImg5}
              sx={{
                height: 240,
                width: '100%',
              }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Box
                borderRadius={'50%'}
                sx={{ width: 56, height: 56 }}
                textAlign={'center'}
                display={'flex'}
                justifyContent={'center'}
                bgcolor={theme.palette.primary.main}
              >
                <Typography style={{ fontSize: '2.3rem' }}>4</Typography>
              </Box>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {post && post.length !== 0 && post[3].title}
                  </Box>
                  {post &&
                    post.length !== 0 &&
                    post[3].description.map((data, desIndex) => (
                      <Typography
                        align={'left'}
                        variant={data.variant}
                        color="textSecondary"
                        key={desIndex}
                      >
                        {data.description}
                      </Typography>
                    ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <CardMedia
              image={teamImg6}
              sx={{
                height: 240,
                width: '100%',
              }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Box
                borderRadius={'50%'}
                sx={{ width: 56, height: 56 }}
                textAlign={'center'}
                display={'flex'}
                justifyContent={'center'}
                bgcolor={theme.palette.primary.main}
              >
                <Typography style={{ fontSize: '2.3rem' }}>5</Typography>
              </Box>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {post && post.length !== 0 && post[4].title}
                  </Box>
                  {post &&
                    post.length !== 0 &&
                    post[4].description.map((data, desIndex) => (
                      <Typography
                        align={'left'}
                        variant={data.variant}
                        color="textSecondary"
                        key={desIndex}
                      >
                        {data.description}
                      </Typography>
                    ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <CardMedia
              image={teamImg1}
              sx={{
                height: 240,
                width: '100%',
              }}
            />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <Box
                borderRadius={'50%'}
                sx={{ width: 56, height: 56 }}
                textAlign={'center'}
                display={'flex'}
                justifyContent={'center'}
                bgcolor={theme.palette.primary.main}
              >
                <Typography style={{ fontSize: '2.3rem' }}>6</Typography>
              </Box>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Box
              display={'block'}
              width={'100%'}
              height={'100%'}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                borderRadius={3}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    component={Typography}
                    variant={'h6'}
                    gutterBottom
                    fontWeight={500}
                    align={'left'}
                  >
                    {post && post.length !== 0 && post[5].title}
                  </Box>
                  {post &&
                    post.length !== 0 &&
                    post[5].description.map((data, desIndex) => (
                      <Typography
                        align={'left'}
                        variant={data.variant}
                        color="textSecondary"
                        key={desIndex}
                      >
                        {data.description}
                      </Typography>
                    ))}
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}
