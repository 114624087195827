import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Map from './components/Map/Map';
import Form from './components/Form/Form';
// import Faq from './components/Faq/Faq';
import Hero from './components/hero/Hero';
import Container from '../../common/Container';

import backgroundImg4 from '../../assets/img/backgroundImg4.png';

const Contact = ({ themeMode = 'light' }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box
        minHeight={{ xs: 'auto', md: 'calc(35vh - 64px)' }}
        height={'auto'}
        position={'relative'}
        sx={{
          backgroundImage: `url(${backgroundImg4})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center right',
          zIndex: 10,
          '&:after': {
            position: 'absolute',
            content: '" "',
            width: '100%',
            height: '100%',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: theme.palette.common.black,
            opacity: 0.3,
          },
        }}
      >
        <Box
          position={{ xs: 'relative', md: 'absolute' }}
          top={{ xs: 0, md: '50%' }}
          width={'100%'}
          height={'50%'}
          sx={{
            transform: { md: 'translateY(-50%)' },
          }}
          zIndex={2}
        >
          <Container>
            <Hero />
          </Container>
        </Box>
      </Box>
      <Container>
        <Grid container spacing={isMd ? 8 : 4}>
          <Grid item xs={12} md={6}>
            <Map themeMode={themeMode} />
          </Grid>
          <Grid item container xs={12} md={6} alignItems={'center'}>
            <Form />
          </Grid>
        </Grid>
      </Container>
      {/* <Box bgcolor={theme.palette.alternate.main}>
        <Container maxWidth={800}>
          <Faq />
        </Container>
      </Box> */}
    </Box>
  );
};

Contact.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Contact;
