/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { createInquiryEmailAction } from "../../../../redux/actions/contentAction";

const Form = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  /**
 |--------------------------------------------------
 | redux integration
 |--------------------------------------------------
 */
  const dispatch = useDispatch();

  /**
 |--------------------------------------------------
 | formik property
 |--------------------------------------------------
 */
  const initialValues = {
    firstName: "",
    lastName: "",
    content: "",
    fromMail: "",
  };

  const validationSchema = yup.object({
    fromMail: yup
      .string("Enter your email")
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),

    firstName: yup.string("First name").required("First Name is required."),

    lastName: yup.string("Last name").required("Last name is required."),

    content: yup.string("Message").required("Message is required."),
  });

  const onSubmit = (values, formikProps) => {
    values.username = "adminDamien";

    dispatch(createInquiryEmailAction(values));
    formikProps.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      {isMd && (
        <Box
          style={{
            position: "absolute",
            zIndex: 0,
            top: -100,
            right: 0,
          }}
        >
          <svg
            width="446"
            height="527"
            viewBox="0 0 446 527"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="263.5"
              cy="263.5"
              r="263.5"
              fill="url(#paint0_linear_400_167)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_400_167"
                x1="444.404"
                y1="79.7752"
                x2="109.59"
                y2="422.648"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#11B735" />
                <stop offset="1" stop-color="#8AF7A2" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      )}

      <Box
        padding={{ xs: 3, sm: 6 }}
        width={"100%"}
        component={Card}
        borderRadius={2}
        boxShadow={4}
        marginBottom={4}
        zIndex={10}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                placeholder="First name"
                name={"firstName"}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                placeholder="Last name"
                name={"lastName"}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                placeholder="Your email address"
                name={"fromMail"}
                value={formik.values.fromMail}
                onChange={formik.handleChange}
                error={
                  formik.touched.fromMail && Boolean(formik.errors.fromMail)
                }
                helperText={formik.touched.fromMail && formik.errors.fromMail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                multiline
                rows={6}
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                placeholder="Your Message"
                name={"content"}
                value={formik.values.content}
                onChange={formik.handleChange}
                error={formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ height: 54 }}
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                type={"submit"}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Typography color="text.secondary" align={"center"}>
          We'll get back to you in 1-2 business days.
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
